import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any, argsObj?: any): any {
    if (!value) {
      return null;
    }
    if (argsObj == undefined || argsObj == {} || argsObj == "") {
      return value;
    }
    return value.filter(function (obj: any) {
      if (argsObj['filterkey'] == undefined) {
        return true;
      }
      else {
        for (let key in obj) {
          for (let argKey in argsObj) {
            if (key == argKey) {
              if(obj[key]!=null && obj[key]!="")
              if (obj[key].toString().toLowerCase().includes(argsObj[key].toLowerCase())) {
                return true;
              }
              else {
                continue;
              }
            }

          }
        }
      }

    });

    // return value.filter(function (item: any) {
    //   return JSON.stringify(item).toLowerCase().includes(args);
    //   });

  }

}
