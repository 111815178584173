import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ViewChildren,
  QueryList
} from "@angular/core";
import { Genericservice } from "src/app/services/genericservices";
import { EncryptDecryptLocalStorageService } from "src/app/services/encrypt-decrypt-localstorage.service";
import { UrlConstant } from "src/app/services/urlConstant";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { WindowRef } from "src/app/services/WindowRefservices";
import {
  HttpClient,
  HttpRequest,
  HttpHeaders,
  HttpEvent
} from "@angular/common/http";
import * as jspdf from "jspdf";

import html2canvas from "html2canvas";
import { DataSharingService } from "src/app/services/dataSharing.service";
import { environment } from "src/environments/environment";
import { NgbDatepicker } from "@ng-bootstrap/ng-bootstrap";
import {
  NgbdSortableHeader,
  SortEvent
} from "src/app/directives/sortable.directive";
import * as moment from "moment";

@Component({
  selector: "app-leavereports",
  templateUrl: "./leavereports.component.html",
  styleUrls: ["./leavereports.component.scss"],
  providers: [WindowRef]
})
export class LeavereportsComponent implements OnInit, AfterViewInit {
  public showFromDate: any;
  public showToDate: any;
  gemsDepartment;
  public showDateToModel: any = "Date";
  public methodChangeFromDate: boolean;
  leavereportlist;
  leaveTypeControl: FormControl;
  showfilters: boolean;
  leavedepartmentslist: any;
  Leavereportemployeelist: any;
  leavetypelist: { value: string; viewValue: string }[];
  departmentlist: { value: string; viewValue: string }[];
  leavetypelists: any;
  leavereportForm: FormGroup;
  FromDate: string;
  ToDate: string;
  employeeMaster: any;
  employeeControl: any;
  dept: any;
  departmentControl: any;
  leaveType: any;
  Leavereportemplist = [];
  public leaveReportList: any = [];
  gemsEmployeeMasterId;
  totalCount: any;
  start = 0;
  limit = 10;
  totalPages: number;
  currentPage = 1;
  selectedItemsPerPage = 10;
  paginateSummaryList: any;
  selectedEmpStatus: string;
  selectedOption: any;
  public pageOptions = [
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "50", label: "50" },
    { value: "100", label: "100" },
    { value: "500", label: "500" }
  ];
  selectExcelFileName: any = "";
  order: any;
  orderBy: any;
  toDay: any;
  date = new Date();
  public orderBoolean = false;
  public preloader: boolean = false;
  isData: boolean = true;
  http: any;
  Date = new Date();
  maxDate: any;
  fromdateModel: any;
  toDateModel: any;
  organisationId: any;
  @ViewChild("dpfromDate", { static: false }) datepicker1: NgbDatepicker;
  @ViewChild("dptoDate", { static: false }) datepicker2: NgbDatepicker;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(
    private genericservice: Genericservice,
    public httpClient: HttpClient,
    private dataSharingService: DataSharingService,
    private encryptdecryptlocalstorage: EncryptDecryptLocalStorageService,
    private winRef: WindowRef
  ) {
    this.showfilters = false;
    this.fromdateModel = this.dataSharingService.resetDate();
    this.toDateModel = this.dataSharingService.resetDate();
    this.maxDate = this.dataSharingService.resetDate();
    this.organisationId = this.encryptdecryptlocalstorage.getLocalStorage(
      "organizationId"
    );
  }
  public leavereportsUrl = UrlConstant.leavereportsApi;
  public LeavereportlistUrl = UrlConstant.leavereportslistApi;
  public LeavereportemployeeUrl = UrlConstant.leavereportsemployeeApi;
  public employeelistUrl = UrlConstant.getLeaveReportWithPagination;
  public exporeavetLUrl = UrlConstant.exportLeaveReport;
  viewEmployeeStatusURL = UrlConstant.departmentlistByStaus;
  ngOnInit() {
    this.start = 0;
    this.limit = this.selectedItemsPerPage;
    this.getAllReportList();
    this.getleavetypelist();
    this.getdepartmentlists();
    this.getemployeelist();

    this.leavereportForm = new FormGroup({
      from: new FormControl("", Validators.required),
      to: new FormControl("", Validators.required),
      leavetypes: new FormControl(),
      departments: new FormControl(),
      employee: new FormControl(""),
      status: new FormControl("")
    });

    this.leavereportForm.valueChanges.subscribe(value => {});
  }

  ngAfterViewInit() {
    this.datepicker1.navigateTo(this.dataSharingService.resetDate());
    this.datepicker2.navigateTo(this.dataSharingService.resetDate());
  }
  
  setOrder(value: string, orderBy) {
    if (this.order === value) {
      // this.reverse = !this.reverse;
      this.orderBoolean = !this.orderBoolean;
      this.orderBy = orderBy;
    }
    this.order = value;
  }

  public captureScreen() {
    if (this.leaveReportList.length != 0) {
      var data = document.getElementById("contentToConvert");
      html2canvas(data).then(canvas => {
        var imgWidth = 208;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        const contentDataURL = canvas.toDataURL("image/png");
        let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
        var position = 0;
        pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);

        if (this.showFromDate == undefined && this.showToDate == undefined) {
          pdf.save("LeaveReport.pdf");
        } else {
          pdf.save(
            "LeaveReport_From" +
              this.showFromDate +
              "_To_" +
              this.showToDate +
              ".pdf"
          );
        }
      });
    } else {
      this.dataSharingService.failureMessage(
        "Atleast one record should be required to download file"
      );
    }
  }

  resetFilterFromDate() {
    this.showFromDate = "";
    this.leavereportForm.get("from").setValue("");
    this.fromdateModel = this.dataSharingService.resetDate();
    this.datepicker1.navigateTo(this.dataSharingService.resetDate());
  }
  resetFilterToDate() {
    this.showToDate = "";
    this.leavereportForm.get("to").setValue("");
    this.toDateModel = this.dataSharingService.resetDate();
    this.datepicker2.navigateTo(this.dataSharingService.resetDate());
  }

  onFilterFromDatechange(event: any) {
    this.methodChangeFromDate = true;
    if (event !== undefined) {
      var date = event.year + "-" + event.month + "-" + event.day;
      this.leavereportForm.get("from").setValue(date);
      this.showFromDate = date;
    }
  }
  onFilterToDatechange(event: any) {
    this.methodChangeFromDate = false;
    if (event !== undefined) {
      var date = event.year + "-" + event.month + "-" + event.day;
      this.showToDate = date;

      this.leavereportForm.get("to").setValue(date);
    }
  }

  clickfilter() {
    this.showfilters = !this.showfilters;
    $('#changeHeight').toggleClass("scroll-1");
    $('#changeHeight').toggleClass("smallHeight");
  }

  // GET LEAVE TYPE LIST
  getleavetypelist() {
    let payload = {
      gemsOrganisation: {
        gemsOrgId: this.organisationId,
        isActive: true
      }
    };
    this.genericservice
      .post(this.LeavereportlistUrl, payload, false)
      .subscribe(leavetypesresponse => {
        this.leavetypelists = leavetypesresponse.data;
      });
  }

  // GET DEPARTMENT LIST

  getdepartmentlists() {
    let payload = { gemsOrganisation: { gemsOrgId: this.organisationId } };
    this.genericservice
      .post(this.leavereportsUrl, payload, false)
      .subscribe(departmentresponse => {
        this.leavedepartmentslist = departmentresponse.data;
      });
  }

  // GET EMPLOYEE LIST
  getemployeelist() {
    // this.preloader = true;
    if (this.selectedEmpStatus) {
      var payload = {
        gemsOrganisationId: this.organisationId,
        isActive: this.selectedEmpStatus
      };
    } else {
      var payload = {
        gemsOrganisationId: this.organisationId,
        isActive: "true"
      };
    }

    this.genericservice
      .post(this.LeavereportemployeeUrl, payload, false)
      .subscribe(employeelistresponse => {
        if (employeelistresponse.data != null) {
          this.Leavereportemployeelist = employeelistresponse.data;
        } else {
          this.Leavereportemployeelist = [];
        }
        // this.preloader = false;
      });
  }

  clear() {
    this.leavereportForm.reset();
    this.showfilters = false;
    this.datepicker1.navigateTo(this.dataSharingService.resetDate());
    this.datepicker2.navigateTo(this.dataSharingService.resetDate());
    this.start = 0;
    this.limit = 10;
    this.getAllReportList();
    this.resetFilterFromDate();
    this.resetFilterToDate();
    $('#changeHeight').removeClass("smallHeight");
    $('#changeHeight').toggleClass('scroll-1');
  }

  // FILTER
  clicksubmit() {
    //
    this.leaveReportList = [];
    this.employeeMaster = this.leavereportForm.value.employee;
    this.dept = this.leavereportForm.value.departments;
    this.leaveType = this.leavereportForm.value.leavetypes;
    let gemsEmployeeMasterId = "";
    this.leavereportForm.value.employee != null &&
    this.leavereportForm.value.employee !== ""
      ? (gemsEmployeeMasterId = this.leavereportForm.value.employee
          .gemsEmployeeMasterId)
      : (gemsEmployeeMasterId = null);

    this.leavereportForm.value.departments != null &&
    this.leavereportForm.value.departments !== ""
      ? (this.dept = this.leavereportForm.value.departments)
      : (this.dept = null);

    this.leavereportForm.value.leavetypes != null &&
    this.leavereportForm.value.leavetypes !== ""
      ? (this.leaveType = this.leavereportForm.value.leavetypes)
      : (this.leaveType = null);

    let reportObj = {
      createdBy: {
        gemsEmployeeMasterId: gemsEmployeeMasterId,
        gemsOrganisation: {
          gemsOrgId: this.organisationId
        },
        gemsDepartment: this.dept
      },
      gemsEmplyeeLeaveSummary: {
        gemsLeaveTypeMaster: this.leaveType
      }
    };

    var reqParam = `?fromDate=${this.leavereportForm.value.from}&toDate=${this.leavereportForm.value.to}&start=${this.start}&limit=${this.limit}`;
    this.preloader = true;
    this.totalCount = 0;
    this.genericservice
      .post(this.employeelistUrl + reqParam, reportObj, false)
      .subscribe(employeeresponse => {
        this.preloader = false;
        if (employeeresponse.data) {
          this.leaveReportList = this.calculatePermisson(employeeresponse.data);
          this.totalCount = employeeresponse.totalResult
            ? employeeresponse.totalResult
            : 0;
          // this.paginatedSummaryList();
          this.findItemsPerPage();
        }
      });
  }

  // DOWNLOAD
  selectEmpStatus(e) {
    this.leavereportForm.controls["employee"].setValue("");
    this.selectedEmpStatus = e.target.value;

    this.getemployeelist();
  }

  exportLeaveReport(downloadType) {
    let ourDate = new Date();
    var pastDate = ourDate.getDate() - 7;
    ourDate.setDate(pastDate);
    var prevDate = new Date(ourDate.setDate(pastDate)),
      mnth = ("0" + (prevDate.getMonth() + 1)).slice(-2),
      day = ("0" + prevDate.getDate()).slice(-2);
    this.FromDate = [prevDate.getFullYear(), mnth, day].join("-");

    let currentDate = new Date();
    var toDate = currentDate.getDate() - 1;
    var date = new Date(ourDate.setDate(toDate)),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    this.ToDate = [date.getFullYear(), mnth, day].join("-");
    if (
      this.leavereportForm.value.from == "" ||
      this.leavereportForm.value.from == null ||
      this.leavereportForm.value.from == undefined
    ) {
      this.leavereportForm.value.from = this.FromDate;
    }
    if (
      this.leavereportForm.value.to == "" ||
      this.leavereportForm.value.to == null ||
      this.leavereportForm.value.to == undefined
    ) {
      this.leavereportForm.value.to = this.ToDate;
    }
    let gemsEmployeeMasterId;
    let gemsDepartmentId;
    let gemsLeaveTypeMasterId;

    this.leavereportForm.value.dept != null
      ? (gemsDepartmentId = this.dept.gemsDepartmentId + "")
      : (gemsDepartmentId = "");

    this.leavereportForm.value.employee != null &&
    this.leavereportForm.value.employee != ""
      ? (gemsEmployeeMasterId = this.employeeMaster.gemsEmployeeMasterId + "")
      : (gemsEmployeeMasterId = "");

    if (
      this.leavereportForm.value.leavetypes == null ||
      this.leavereportForm.value.leavetypes == "" ||
      this.leavereportForm.value.leavetypes == undefined
    ) {
      gemsLeaveTypeMasterId = "";
    } else {
      gemsLeaveTypeMasterId =
        this.leavereportForm.value.leavetypes.gemsLeaveTypeMasterId + "";
    }

    if (
      gemsEmployeeMasterId == "" ||
      gemsEmployeeMasterId == undefined ||
      gemsEmployeeMasterId == "undefined"
    ) {
      gemsEmployeeMasterId = "";
    }
    if (
      gemsLeaveTypeMasterId == undefined ||
      gemsLeaveTypeMasterId == "" ||
      gemsLeaveTypeMasterId == "undefined"
    ) {
      gemsLeaveTypeMasterId = "";
    }
    if (
      gemsDepartmentId == "" ||
      gemsDepartmentId == null ||
      gemsDepartmentId == undefined ||
      gemsDepartmentId == "undefined"
    ) {
      gemsDepartmentId = "";
    }
    let baseUrL = environment.apiUrl;

    let LEAVE_EXPORT_POST = baseUrL + this.exporeavetLUrl + "?fromDate=";
    let serviceURL =
      LEAVE_EXPORT_POST +
      this.leavereportForm.value.from +
      "" +
      "&toDate=" +
      this.leavereportForm.value.to +
      "&fileType=" +
      downloadType +
      "&gemsOrgId=" +
      this.organisationId +
      "&gemsEmployeeMasterId=" +
      gemsEmployeeMasterId +
      "&gemsDepartmentId=" +
      gemsDepartmentId +
      "&gemsLeaveTypeMasterId=" +
      gemsLeaveTypeMasterId;
    this.winRef.getNativeWindow().open(serviceURL, "_blank");
  }

  // GET ALL REPORTS BEFORE 7DAYS
  getAllReportList() {
    let ourDate = new Date();
    var pastDate = ourDate.getDate() - 7;
    ourDate.setDate(pastDate);
    var prevDate = new Date(ourDate.setDate(pastDate)),
      mnth = ("0" + (prevDate.getMonth() + 1)).slice(-2),
      day = ("0" + prevDate.getDate()).slice(-2);
    this.FromDate = [prevDate.getFullYear(), mnth, day].join("-");

    let currentDate = new Date();
    var toDate = currentDate.getDate() - 1;
    var date = new Date(ourDate.setDate(toDate)),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    this.ToDate = [date.getFullYear(), mnth, day].join("-");

    let reportObj = {
      createdBy: {
        gemsOrganisation: { gemsOrgId: this.organisationId }
      },
      gemsEmplyeeLeaveSummary: {}
    };
    this.preloader = true;
    var reqParam = `?fromDate=${this.FromDate}&toDate=${this.ToDate}&start=${this.start}&limit=${this.limit}`;
    this.totalCount = 0;
    this.genericservice
      .post(this.employeelistUrl + reqParam, reportObj, false)
      .subscribe(employeeresponse => {
        if (employeeresponse.data != null) {
          this.leaveReportList = this.calculatePermisson(employeeresponse.data);

          this.totalCount = employeeresponse.totalResult
            ? employeeresponse.totalResult
            : 0;
          this.findItemsPerPage();
        } else {
          this.leaveReportList = [];
          this.totalCount = 0;
        }
        this.preloader = false;
      });
  }

  calculatePermisson(response) {
    var i;
    var list = [];
    for (i = 0; i < response.length; i++) {
      var responseObj = response[i];
      responseObj.permissionDuration = this.getPermissonDuration(responseObj);
      list.push(responseObj);
    }
    return list;
  }

  /*
   *  TO GET LEAVE DURATION FORMAT BY ITS TYPE
   */
  getPermissonDuration(responseObject) {
    var startDate = moment(responseObject.fromDate).format("DD-MM-YYYY");
    var endDate = moment(responseObject.toDate).format("DD-MM-YYYY");
    if (responseObject.transactionType == "PER") {
      responseObject.permissionDuration = responseObject.permissionDuration.toString();
      if (responseObject.permissionDuration.includes(".")) {
        let hour = responseObject.permissionDuration.split(".")[0];
        let minutes = responseObject.permissionDuration.split(".")[1];
        if (hour.length > 0) {
          if (minutes.length > 0) {
            if (hour === "0" || hour === 0) {
              minutes =
                minutes.length == 1
                  ? minutes + "0" + " " + "mins"
                  : minutes === "01"
                  ? minutes + " " + "min"
                  : minutes + " " + "mins";
              return minutes;
            } else {
              hour =
                hour === "1" || hour === 1
                  ? hour + " " + "hr"
                  : hour + " " + "hrs";
              minutes =
                minutes.length == 1
                  ? minutes + "0" + " " + "mins"
                  : minutes === "01"
                  ? minutes + " " + "min"
                  : minutes + " " + "mins";
              return hour + " " + minutes;
            }
          }
        }
      } else {
        responseObject.permissionDuration =
          responseObject.permissionDuration === "1" ||
          responseObject.permissionDuration === 1
            ? responseObject.permissionDuration + " " + "hr"
            : responseObject.permissionDuration + " " + "hrs";
        return responseObject.permissionDuration;
      }
    } else if (responseObject.transactionType == "OD" && startDate == endDate) {
      responseObject.permissionDuration = responseObject.permissionDuration.toString();

      if (responseObject.permissionDuration.includes(".")) {
        let hour = responseObject.permissionDuration.split(".")[0];
        let minutes = responseObject.permissionDuration.split(".")[1];

        if (hour.length > 0) {
          if (minutes.length > 0) {
            if (hour === "0" || hour === 0) {
              minutes =
                minutes.length == 1
                  ? minutes + "0" + " " + "mins"
                  : minutes === "01"
                  ? minutes + " " + "min"
                  : minutes + " " + "mins";
              return minutes;
            } else {
              hour =
                hour === "1" || hour === 1
                  ? hour + " " + "hr"
                  : hour + " " + "hrs";
              minutes =
                minutes.length == 1
                  ? minutes + "0" + " " + "mins"
                  : minutes === "01"
                  ? minutes + " " + "min"
                  : minutes + " " + "mins";
              return hour + " " + minutes;
            }
          }
        }
      } else {
        if (responseObject.isFullDay) {
          return responseObject.permissionDuration + " " + "day";
        } else {
          responseObject.permissionDuration =
            responseObject.permissionDuration === "1" ||
            responseObject.permissionDuration === 1
              ? responseObject.permissionDuration + " " + "hr"
              : responseObject.permissionDuration + " " + "hrs";
          return responseObject.permissionDuration;
        }
      }
    } else {
      var noOfDays = responseObject.permissionDuration;
      var noOfWeek = Math.trunc(noOfDays / 7);
      var remainder = noOfDays % 7;

      var days;
      if (noOfWeek >= 1) {
        if (remainder > 0) {
          days = remainder == 1 ? remainder + " day" : remainder + " days";
        } else {
          days = "";
        }

        return noOfWeek > 1
          ? noOfWeek + " weeks " + days
          : noOfWeek + " week " + days;
      } else if (
        responseObject.leaveDescription != "Permission" &&
        noOfDays > 1
      ) {
        return noOfDays + " days";
      } else {
        return noOfDays + " day";
      }
    }
  }

  // TO CONVERT DATE VALUE TO STRING //

  convertDateToDB(dateValue: Date) {
    let date = dateValue;
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }
  goToNext() {
    this.currentPage++;
    this.start++;
    this.limit = this.selectedItemsPerPage;
    this.onChangePagination();
    var obj: any = { column: "", direction: "" };
    this.onSort(obj);
    // this.paginateSummaryList = this.leaveReportList.slice(this.start, this.limit);
  }
  goToPrev() {
    this.currentPage--;
    this.start--;
    this.limit = this.selectedItemsPerPage;
    this.onChangePagination();
    var obj: any = { column: "", direction: "" };
    this.onSort(obj);
    // this.paginateSummaryList = this.leaveReportList.slice(this.start, this.limit);
  }
  findItemsPerPage() {
    this.totalPages = Math.ceil(this.totalCount / this.selectedItemsPerPage);
  }
  changeItemPerPage(event) {
    this.currentPage = 1;
    this.selectedItemsPerPage = parseInt(event);
    this.start = 0;
    this.limit = this.selectedItemsPerPage;
    this.onChangePagination();
    var obj: any = { column: "", direction: "" };
    this.onSort(obj);
  }
  goToPageNo(event) {}
  selectDepartment() {
    this.leavereportForm.controls["employee"].setValue("");
    if (this.selectedOption != "") {
      this.selectedOption = this.selectedOption;
      var payload = {
        gemsOrganisationId: this.organisationId
      };
      this.genericservice
        .post(
          this.viewEmployeeStatusURL +
            "?departmentId=" +
            this.selectedOption.gemsDepartmentId +
            "&status=ALL",
          payload,
          false
        )
        .subscribe(response => {
          if (response.data != null) {
            this.Leavereportemployeelist = response.data;
          } else {
            this.Leavereportemployeelist = [];
          }
        });
    }
  }

  onChangePagination() {
    this.leaveReportList = [];
    this.employeeMaster = this.leavereportForm.value.employee;
    this.dept = this.leavereportForm.value.departments;
    this.leaveType = this.leavereportForm.value.leavetypes;
    let gemsEmployeeMasterId = "";
    this.leavereportForm.value.employee != null &&
    this.leavereportForm.value.employee !== ""
      ? (gemsEmployeeMasterId = this.leavereportForm.value.employee
          .gemsEmployeeMasterId)
      : (gemsEmployeeMasterId = null);

    this.leavereportForm.value.departments != null &&
    this.leavereportForm.value.departments !== ""
      ? (this.dept = this.leavereportForm.value.departments)
      : (this.dept = null);

    this.leavereportForm.value.leavetypes != null &&
    this.leavereportForm.value.leavetypes !== ""
      ? (this.leaveType = this.leavereportForm.value.leavetypes)
      : (this.leaveType = null);

    const reportObj = {
      createdBy: {
        gemsEmployeeMasterId: gemsEmployeeMasterId,
        gemsOrganisation: {
          gemsOrgId: this.organisationId
        },
        gemsDepartment: this.dept
      },
      gemsEmplyeeLeaveSummary: {
        gemsLeaveTypeMaster: this.leaveType
      }
    };

    var fromdate = this.leavereportForm.value.from
      ? this.leavereportForm.value.from
      : this.FromDate;
    var todate = this.leavereportForm.value.to
      ? this.leavereportForm.value.to
      : this.ToDate;
    var reqParam = `?fromDate=${fromdate}&toDate=${todate}&start=${this.start}&limit=${this.limit}`;
    this.preloader = true;
    this.genericservice
      .post(this.employeelistUrl + reqParam, reportObj, false)
      .subscribe(employeeresponse => {
        this.preloader = false;
        if (employeeresponse.data) {
          this.leaveReportList = employeeresponse.data;
          this.totalCount = employeeresponse.totalResult
            ? employeeresponse.totalResult
            : 0;
          this.findItemsPerPage();
        } else {
          this.totalCount = 0;
        }
      });
  }

  paginatedSummaryList() {
    if (this.leaveReportList) {
      this.paginateSummaryList = this.leaveReportList.slice(
        this.start,
        this.limit
      );
    }
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    // sorting countries
    if (direction === "") {
      this.leaveReportList = this.leaveReportList;
    } else {
      this.leaveReportList = this.leaveReportList.sort((a, b) => {
        const res = UrlConstant.compare(
          a[column].toString().toLowerCase(),
          b[column].toString().toLowerCase()
        );
        return direction === "asc" ? res : -res;
      });
    }
  }

  onSortDate({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    // sorting countries

    if (direction === "") {
      this.leaveReportList == 0;
      this.leaveReportList.data = this.leaveReportList.data;
    } else {
      this.leaveReportList.data = this.leaveReportList.data.sort((a, b) => {
        const res = UrlConstant.compareDate(a[column], b[column]);
        return direction === "asc" ? res : -res;
      });
    }
  }
}
