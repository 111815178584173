import { Component, OnInit } from '@angular/core';
import { Genericservice } from 'src/app/services/genericservices';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { EncryptDecryptLocalStorageService } from 'src/app/services/encrypt-decrypt-localstorage.service';
import { UrlConstant } from 'src/app/services/urlConstant';

@Component({
  selector: 'app-login-akku',
  templateUrl: './login-akku.component.html',
  styleUrls: ['./login-akku.component.scss']
})
export class LoginAkkuComponent implements OnInit {
  public akkuLogin = UrlConstant.akkuLoginUrl;
  userName: any;

  constructor(private router: Router,
     private genericservice: Genericservice,
     private activateRoute:ActivatedRoute,
     private encryptdecryptlocalstorage: EncryptDecryptLocalStorageService,
      private loadingBar: LoadingBarService,) { }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe((params: Params) => {
      this.userName = params['userName'];
    })
    this.loginAkkuByUserName();
  }


  loginAkkuByUserName() {
   
  
    this.loadingBar.start();
    this.genericservice.akkuLoginByUserName(this.akkuLogin+this.userName).subscribe(response => {
      if (response.status.success == "Success") {
        if (response.data.completelyFilled != null) {
          this.encryptdecryptlocalstorage.setToLocalStorage("completelyFilled", response.data.completelyFilled);
        } else {
          this.encryptdecryptlocalstorage.setToLocalStorage("completelyFilled", false);
        }
        if (response.data.isAdmin != null) {
          this.encryptdecryptlocalstorage.setToLocalStorage("isAdmin", response.data.isAdmin);
        } else {
          this.encryptdecryptlocalstorage.setToLocalStorage("isAdmin", false);
        }
        //  ;

        //to check intial setup completed or not
        if (response.data.gemsOrgSetupStatus == false) {
          this.router.navigate(['initialsetupcheck']);
        } else {
          if (this.encryptdecryptlocalstorage.getLocalStorage("completelyFilled") == true || this.encryptdecryptlocalstorage.getLocalStorage("completelyFilled") == 'true') {
            this.router.navigate(['user']);
          } else {
            if (this.encryptdecryptlocalstorage.getLocalStorage("isAdmin") == true || this.encryptdecryptlocalstorage.getLocalStorage("isAdmin") == 'true') {
              this.router.navigate(['admin']);
            }
          }
        }


        this.encryptdecryptlocalstorage.setToLocalStorage("userName", this.userName);
        // this.encryptdecryptlocalstorage.setToLocalStorage("userPassword", this.loginForm.value.userPassword);
        this.encryptdecryptlocalstorage.setToLocalStorage("employeeId", response.data.employeeId);
        this.encryptdecryptlocalstorage.setToLocalStorage("organizationId", response.data.organizationId);
        this.encryptdecryptlocalstorage.setToLocalStorage("userId", response.data.userId);
        this.encryptdecryptlocalstorage.setToLocalStorage("gemsUserMasterId", response.data.userId);
        this.encryptdecryptlocalstorage.setToLocalStorage("organisationId", response.data.organizationId);

        localStorage.setItem("isReportingManager", response.data.isReportingManager);


        localStorage.setItem("token", response.token);
        sessionStorage.setItem("token", response.token);
        this.loadingBar.complete();
      }
      else {
        this.router.navigate(['/signIn']);
        localStorage.errorMessage='Incorrect Username';

      }
      this.loadingBar.complete();

    }
      ,
      errorHandler => {
        this.loadingBar.complete();
      })
  }

}
