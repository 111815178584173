import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tokenexpired',
  templateUrl: './tokenexpired.component.html',
  styleUrls: ['./tokenexpired.component.scss']
})
export class TokenexpiredComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
