import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { UrlConstant } from 'src/app/services/urlConstant';
import { Genericservice } from 'src/app/services/genericservices';
import { EncryptDecryptLocalStorageService } from 'src/app/services/encrypt-decrypt-localstorage.service';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl, FormControlName } from '@angular/forms';
import { DataSharingService } from '../../../services/dataSharing.service';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/sortable.directive';

@Component({
  selector: 'app-leavetype',
  templateUrl: './leavetype.component.html',
  styleUrls: ['./leavetype.component.scss']
})
export class LeavetypeComponent implements OnInit {
  [x: string]: any;

  public LeavetypeUrl = UrlConstant.getLeavetypeApi;
  public saveleaveType = UrlConstant.saveLeaveTypeApi;
  leaveTypeList: any = [];
  leaveTypeForm: FormGroup;
  addingleavetype: any;
  empleaveRequestId: string;
  gemsLeaveTypeMasterId: any;
  gemsEmployeeMaster: number;
  leavetypeId: any;
  comments: any;
  organizationId: any;
  selectedEditObj: any;
  selectedDelObj: any = {};
  isCarryforwardLeaveType: boolean;
  date = new Date();
  allowToApplyAfter;
  public months = [
    { value: '0', label: 'Immediately' },
    { value: '1', label: '1 Month' },
    { value: '2', label: '2 Months' },
    { value: '3', label: '3 Months' },
    { value: '4', label: '4 Months' },
    { value: '5', label: '5 Months' },
    { value: '6', label: '6 Months' },
    { value: '7', label: '7 Months' },
    { value: '8', label: '8 Months' },
    { value: '9', label: '9 Months' },
    { value: '10', label: '10 Months' },
    { value: '11', label: '11 Months' },
    { value: '12', label: '12 Months' },
  ];
  public perpageOption = [

    { value: '10', label: '10' },
    { value: '20', label: '20' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '500', label: '500' },
  ];
  selectedItemsPerPage: number = 10;
  totalCount: any;
  start: any;
  limit: any;
  totalPages: number;
  currentPage = 1;
  isPreloader:boolean=false;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(private genericservice: Genericservice,
    private encryptdecryptlocalstorage: EncryptDecryptLocalStorageService,
    private fb: FormBuilder,
    private dataSharingService: DataSharingService) {
      this.organizationId = this.encryptdecryptlocalstorage.getLocalStorage('organizationId')
      

  }
  ngOnInit() {
    this.start=0;
    this.limit=this.selectedItemsPerPage;
    this.getLeavetype();
    this.leaveTypeForm = new FormGroup({
      leaveTypeCode: new FormControl('', [Validators.required,validSpace]),
      leaveTypeDescription: new FormControl('', [Validators.required,validSpace]),
      allowToApplyAfter: new FormControl('', Validators.required),
      maxLeavesperTransaction:new FormControl(),
      isActive: new FormControl(true),
      gemsOrganisation: new FormControl({
        gemsOrgId: new FormControl()
      }),
      createdBy: new FormControl(this.encryptdecryptlocalstorage.getLocalStorage('userId')),
      createdOn: new FormControl(this.date),
      updatedBy: new FormControl(this.encryptdecryptlocalstorage.getLocalStorage('userId')),
      updatedOn: new FormControl(this.date),
      gemsLeaveTypeMasterId: new FormControl('')

    });
    this.subscribe();
  }

  subscribe() {
    this.leaveTypeForm.valueChanges.subscribe(value => {
    })
  }

  // GET LEAVETYPE
  getLeavetype() {
    this.isCarryforwardLeaveType = false

    let payload = {
      "gemsOrganisation": {
        "gemsOrgId": this.organizationId,
        "isActive": true,
      }
    }
    this.isPreloader=true;
    var req =`?start=${this.start}&limit=${this.limit}`;
    return this.genericservice.post(this.LeavetypeUrl+req, payload, false).subscribe(response => {
    this.isPreloader=false;
      this.leaveTypeList = response.data;
      this.totalCount = response.totalResult;
      this.findItemsPerPage();
    },error=>{
            this.isPreloader=false;
    })

  }

  goToNext() {
    this.currentPage++;
    this.start++;
    this.limit = this.selectedItemsPerPage;
    this.onChangePagination();
    var obj:any={column:'', direction:''}
    this.onSort(obj);

  }
  goToPrev() {
    this.currentPage--;
    this.start--;
    this.limit = this.selectedItemsPerPage;
    this.onChangePagination();
    var obj:any={column:'', direction:''}
    this.onSort(obj);
  }
  findItemsPerPage() {
    this.totalPages = Math.ceil(this.totalCount /this.selectedItemsPerPage);
  }
  changeItemPerPage(event) {
    this.currentPage = 1;
    this.selectedItemsPerPage = parseInt(event);
    this.start = 0;
    this.limit = this.selectedItemsPerPage;
    this.onChangePagination();
    var obj:any={column:'', direction:''}
    this.onSort(obj);
  }


  changemonth(event) {

    this.allowToApplyAfter = event;

  }

  //ADD LEAVETYPE
  addleavetype(leaveTypeForm) {
    this.isCarryforwardLeaveType = false

 
    if (this.leaveTypeForm.valid) {
      this.leaveTypeForm.controls.isActive.setValue(true),
      this.leaveTypeForm.controls.createdBy.setValue(this.encryptdecryptlocalstorage.getLocalStorage('userId'))
      this.leaveTypeForm.controls.createdOn.setValue(new Date());
      this.leaveTypeForm.controls.updatedBy.setValue(this.encryptdecryptlocalstorage.getLocalStorage('userId'))
      this.leaveTypeForm.controls.updatedOn.setValue(new Date())
      this.leaveTypeForm.controls.gemsOrganisation.setValue({ "gemsOrgId":this.organizationId  })
      return this.genericservice.post(this.saveleaveType, this.leaveTypeForm.value, true).subscribe(response => {
        this.addingleavetype = response.data;
        this.getLeavetype()
        this.dataSharingService.triggerLeaveTypeList('true')
      })
    }

  }

  // SET VALUES IN EDIT MODAL

  update(leavetype) {
    this.selectedDelObj = {};

    this.selectedEditObj = leavetype;
    this.leaveTypeForm.patchValue({
      leaveTypeCode:leavetype.leaveTypeCode,
      leaveTypeDescription:leavetype.leaveTypeDescription,
      maxLeavesperTransaction:leavetype.maxLeavesperTransaction,

      isActive:leavetype.isActive,
      createdBy:this.encryptdecryptlocalstorage.getLocalStorage('userId'),
      createdOn:leavetype.createdOn,
      updatedBy:this.encryptdecryptlocalstorage.getLocalStorage('userId') ,
      updatedOn:leavetype.updatedOn,
      gemsLeaveTypeMasterId:leavetype.gemsLeaveTypeMasterId,
      gemsOrganisation:leavetype.gemsOrganisation,
      allowToApplyAfter: leavetype.allowToApplyAfter,

    })
  }

  reset() {
    this.leaveTypeForm.reset();
  }

  // UPDATE LEAVETYPE VALUES
  updatevalues() {
    this.isCarryforwardLeaveType = false
    var payload = this.selectedEditObj;
    payload.leaveTypeCode = this.leaveTypeForm.value.leavename;
    payload.leaveTypeDescription = this.leaveTypeForm.value.leavetypedescription;
    payload.maxLeavesperTransaction = this.leaveTypeForm.value.maxLeavesperTransaction;
    var body=this.leaveTypeForm.value;
     body.isCarryforwardLeaveType=false;
     body.Applicablemonth = this.leaveTypeForm.value.allowToApplyAfter

    //  this.selectedEditObj.leaveTypeCode = this.leaveTypeForm.value.leavename;
    // this.selectedEditObj.leaveTypeDescription = this.leaveTypeForm.value.leavetypedescription;

    this.genericservice.post(this.saveleaveType, body, true).subscribe(response => {
      this.getLeavetype();
    });

  }

  // DELETE LEAVE TYPE
  deleteleavetypePopUp(leavetype) {
    this.selectedDelObj = leavetype;
  }

  deleteleaveType() {
    this.selectedDelObj.isActive = false,
      this.genericservice.post(this.saveleaveType, this.selectedDelObj, true).subscribe(response => {
        this.getLeavetype();
        this.dataSharingService.triggerLeaveTypeList('true')
      })
  }


  setOrder(value: string, orderBy) {
    if (this.order === value) {
      // this.reverse = !this.reverse;
      this.orderBoolean = !this.orderBoolean;
      this.orderBy = orderBy;
      // this.orderBy = this.orderBoolean === true ? 'asc' : 'desc';
    }
    this.order = value;
  }
  
  onChangePagination(search?: string) { 
    const requestPayload = {
      "gemsOrganisation": {
        "gemsOrgId": this.organizationId,
        "isActive": true,
      }
    }
      this.isPreloader = true;
      this.leaveTypeList=[];
      var req =`?start=${this.start}&limit=${this.limit}`;
      this.genericservice.post(this.LeavetypeUrl + req, requestPayload, false).subscribe(response => {
        this.isPreloader = false;
        if (response.data) {
          this.leaveTypeList = response.data;
          this.totalCount = response.totalResult;
          this.findItemsPerPage();
        }else{
          this.totalCount=0;
          this.leaveTypeList=[];
        }
      }, error => {
        this.isPreloader = false;
      });
  }
  onSort({column, direction}: SortEvent) {

    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting countries
    if (direction === '') {
      this.leaveTypeList = this.leaveTypeList;
    } else {
      this.leaveTypeList = this.leaveTypeList.sort((a, b) => {
       

        const res = UrlConstant.compare(a[column]?a[column].toString().toLowerCase():null, b[column]?b[column].toString().toLowerCase():null);
        return direction === 'asc' ? res : -res;
      });
    }
  }
  
  onSortNumber({column, direction}: SortEvent) {

    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting countries
    if (direction === '') {
      this.leaveTypeList = this.leaveTypeList;
    } else {
      this.leaveTypeList = this.leaveTypeList.sort((a, b) => {
       

        const res = UrlConstant.compare(a[column]?a[column]:null, b[column]?b[column]:null);
        return direction === 'asc' ? res : -res;
      });
    }
  }
  
  onSortDate({column, direction}: SortEvent) {

    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting countries
    if (direction === '') {
      this.leaveTypeList = this.leaveTypeList;
    } else {
      this.leaveTypeList = this.leaveTypeList.sort((a, b) => {
       

        const res = UrlConstant.compareDate(a[column]?a[column].toString().toLowerCase():null, b[column]?b[column].toString().toLowerCase():null);
        return direction === 'asc' ? res : -res;
      });
    }
  }



}
export function validSpace(control: AbstractControl) {
  if (control && control.value != null) {
      let ControlValue = control.value.trim();
      if (!ControlValue) {
          return { validUrl: true };
      }
  }
  return null;
}
