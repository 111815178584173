import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-set-up-finish',
  templateUrl: './set-up-finish.component.html',
  styleUrls: ['./set-up-finish.component.scss']
})
export class SetUpFinishComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // this.noBack();
  }

  noBack()
        {
    // window.history.go(-1);

            // window.history.forward();
            
        }
}
