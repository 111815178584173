import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Genericservice } from 'src/app/services/genericservices';
import { UrlConstant } from 'src/app/services/urlConstant';
import { DataSharingService } from 'src/app/services/dataSharing.service';

@Component({
  selector: 'approval-timesheet',
  templateUrl: './approval-timesheet.component.html',
  styleUrls: ['./approval-timesheet.component.scss']
})
export class ApprovalTimeSheetComponent implements OnInit {
  getTimeLineByIdApiUrl = UrlConstant.getTimeLineById;
  saveTimeLineFromMailApi = UrlConstant.saveTimeLineFromMailApi;

  message: any = '';
  timeLineId: any;
  timeLineStatus: any = '';
  TimeLineObject: any = '';

  constructor(private activatedRoute: ActivatedRoute,
    private dataSharingService: DataSharingService, private genericService: Genericservice) { }

  ngOnInit() {
    this.getToken()
  }

  getToken() {

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      var approvalToken = params['approvalToken'];
      var rejectedToken = params['rejectedToken'];
      this.timeLineId = approvalToken ? approvalToken : rejectedToken;
      this.timeLineStatus = approvalToken ? 'APPROVED' : 'REJECTED';


    });

    this.getTimeLineById();
  }

  getTimeLineById() {
    var req = {
      "cntEmployeeDailyTimeSheetID": this.timeLineId
    }

    this.genericService.postWithOutHeaders(this.getTimeLineByIdApiUrl, req, false).subscribe(response => {

      if (response.data.cntEmployeeDailyTimeSheet) {
        this.TimeLineObject = response.data.cntEmployeeDailyTimeSheet;
        var status = this.TimeLineObject.status == this.timeLineStatus ?
          this.timeLineStatus : this.TimeLineObject.status;
        if (this.TimeLineObject.status != 'DRAFT' && this.TimeLineObject.isFlag == true) {
          this.message = "TimeSheet has been " + status + " already";
        } else {
          this.approveTimeLine();
        }
      } else {
        this.dataSharingService.failureMessage('Something went wrong');
      }
    }, error => {
      this.dataSharingService.failureMessage('Something went wrong');

    });
  }

  approveTimeLine() {

    this.TimeLineObject.status = this.timeLineStatus;
    this.TimeLineObject.isFlag = true;
    this.TimeLineObject.updatedBy = this.TimeLineObject.approvalBy.gemsEmployeeMasterId;
    var req = { "cntEmployeeDailyTimeSheet": this.TimeLineObject }
    this.genericService.postWithOutHeaders(this.saveTimeLineFromMailApi, req, false).subscribe(response => {

      if (response.status.success == 'Success') {
        this.message = "TimeSheet has been " + this.timeLineStatus + " successfully";
      }
    }, error => {
      this.dataSharingService.failureMessage('Something went wrong');

    });
  }

}
