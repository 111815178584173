import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Injectable({ providedIn: 'root' })
export class DataSharingService {
    constructor(private calendar: NgbCalendar) {
    }
    

    public successNotificationBs = new BehaviorSubject<string>('');
    public successNotificationObs = this.successNotificationBs.asObservable();

    public failureNotificationBs = new BehaviorSubject<string>('');
    public failureNotificationObs = this.failureNotificationBs.asObservable();

    public appliedFlagBs=new BehaviorSubject<string>('');
    public appliedFlagObs=this.appliedFlagBs.asObservable();

    public getNextCompBs=new BehaviorSubject<string>('');
    public getNextCompObs=this.getNextCompBs.asObservable();

    public profileFlagBs=new BehaviorSubject<string>('');
    public profileFlagObs=this.profileFlagBs.asObservable();
    public leaveTypeListBs=new BehaviorSubject<string>(' ');
    public leaveTypeListObs=this.leaveTypeListBs.asObservable();

    successMessage(notification) {
   this.successNotificationBs.next(notification);
    }
    failureMessage(notification) {
   this.failureNotificationBs.next(notification);
    }

    trigegerApplyFlag(applied){
        this.appliedFlagBs.next(applied)
    }

    trigegerprofileFlag(isUpdateProfile){
        this.profileFlagBs.next(isUpdateProfile)
    }
    triggerUserMappingFlag(isUpdateProfile){
        this.getNextCompBs.next(isUpdateProfile)
    }
    
  triggerLeaveTypeList(isUpadteList){
      this.leaveTypeListBs.next(isUpadteList)
  }
resetDate(){
  return this.calendar.getToday();
}
}