import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Genericservice } from 'src/app/services/genericservices';
import { UrlConstant } from 'src/app/services/urlConstant';
import { EncryptDecryptLocalStorageService } from 'src/app/services/encrypt-decrypt-localstorage.service';
import { DataSharingService } from 'src/app/services/dataSharing.service';

@Component({
  selector: 'app-admin-generation-import-users',
  templateUrl: './admin-generation-import-users.component.html',
  styleUrls: ['./admin-generation-import-users.component.scss']
})
export class AdminGenerationImportUsersComponent implements OnInit {
  public fileName = '';
  isFileChoosen: boolean = false;
  selectedFiles: File;
  importUsersURL = UrlConstant.importUsersApi;
  public preloader: boolean = false;
  public errorMessage = '';
  @ViewChild('inputFile', { static: false }) inputFile: ElementRef;
  constructor(public genericService: Genericservice, public encryptdecryptlocalstorage: EncryptDecryptLocalStorageService, public dataSharingService: DataSharingService) { }

  ngOnInit() {
  }
  chooseFile(event: any) {
    this.isFileChoosen = false;
    if (event.currentTarget.files[0].name.includes('demo')) {
      this.errorMessage = '';
      if (event.currentTarget.files[0].name.split('.').pop() == 'csv'
        || event.currentTarget.files[0].name.split('.').pop() == 'xlsx'
        || event.currentTarget.files[0].name.split('.').pop() == 'xls') {
        if (event.currentTarget.files[0].size <= 5000000) {
          if (event != undefined) {
            this.isFileChoosen = true;
          }

          let reader = new FileReader();
          if (event.target.files && event.target.files.length > 0) {
            this.fileName = event.target.files[0].name;
            let fileData: File = event.target.files[0];
            this.selectedFiles = event.target.files[0]
          }
        } else {
          this.errorMessage = 'Please upload file with less than 5 MB.';
        }
      } else {
        this.errorMessage = 'Please upload file having extensions .csv/.xlsx/.xls.';
      }
    }
    else {
      this.errorMessage = 'Please upload only downloaded sample file';
    }
  }

  resetFile() {
    this.selectedFiles = undefined;
    this.isFileChoosen = false;
    this.fileName = '';
    this.inputFile.nativeElement.value = '';
    this.errorMessage = '';
  }

  submitFile() {
    this.preloader = true;
    let id = parseInt(this.encryptdecryptlocalstorage.getLocalStorage('organisationId'));
    this.genericService.csvUpload(this.importUsersURL + '?organisationId=' + id, this.selectedFiles, true).subscribe(response => {

      if (response.type == 4) {
        if (response['body'].status.success == 'Fail') {
          this.dataSharingService.failureMessage(response['body'].status.message)
        }
        else if (response['body'].status.success == 'Success') {
          this.dataSharingService.triggerUserMappingFlag('true');
          this.inputFile.nativeElement.value = '';
          this.errorMessage = '';
          this.dataSharingService.successMessage(response['body'].status.message);
          this.tabNext();
        }
      }
      this.preloader = false;

    }, error => {
      this.preloader = false;
      this.dataSharingService.failureMessage('Oops! Something went wrong.')
    });
  }

  tabNext() {
    $('#nav-mapping').addClass('active show');
    $('#nav-import').removeClass('active show');
    $('#nav-mapping-tab').addClass('active show');
    $('#nav-import-tab').removeClass('active show');
  }
  tabPrev() {
    $('#nav-organisation').addClass('active show');
    $('#nav-import').removeClass('active show');
    $('#nav-organisation-tab').addClass('active show');
    $('#nav-import-tab').removeClass('active show');
  }
  downloadSample() {
    location.href = UrlConstant.downloadAttendance;
  }
}
