import { NgModule } from '@angular/core';
import { PaginatePipe } from './paginate/paginate.pipe';
import { SearchPipe } from './search/search.pipe';
import { SortPipe } from './sort/sort.pipe';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbdSortableHeader } from 'src/app/directives/sortable.directive';

@NgModule({
    imports: [
   CommonModule
      
    ],
    declarations: [
        PaginatePipe,
        SearchPipe,
        SortPipe,
        NgbdSortableHeader
        
        // FormsModule
    ],
    exports: [
        PaginatePipe,
        SearchPipe,
        SortPipe,
        NgbdSortableHeader
    ],
    providers: [
    ]
  
  })
  export class SharedModule { }