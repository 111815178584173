import { Component, OnInit } from '@angular/core';
import { UrlConstant } from '../../../services/urlConstant';
import { EncryptDecryptLocalStorageService } from '../../../services/encrypt-decrypt-localstorage.service';
import { Genericservice } from '../../../services/genericservices';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-leavedetails',
  templateUrl: './leavedetails.component.html',
  styleUrls: ['./leavedetails.component.scss']
})
export class LeavedetailsComponent implements OnInit {
  public leavedetailsForm: FormGroup;

  public getleaveparamapiUrl = UrlConstant.getLeaveParamAPi;
  public saveparamUrl = UrlConstant.saveorganizationparamApi;

  public months = [
    { value: '1', label: '1 Month' },
    { value: '2', label: '2 Month' },
    { value: '3', label: '3 Month' },
    { value: '4', label: '4 Month' },
    { value: '5', label: '5 Month' },
    { value: '6', label: '6 Month' },
    { value: '7', label: '7 Month' },
    { value: '8', label: '8 Month' },
    { value: '9', label: '9 Month' },
    { value: '10', label: '10 Months' },
    { value: '11', label: '11 Months' },
    { value: '12', label: '12 Months' },
    { value: '13', label: '13 Months' },
    { value: '14', label: '14 Months' },
    { value: '15', label: '15 Months' },
    { value: '16', label: '16 Months' },
    { value: '17', label: '17 Months' },
    { value: '18', label: '18 Months' },
    { value: '19', label: '19 Months' },
    { value: '20', label: '20 Months' },
    { value: '21', label: '21 Months' },
    { value: '22', label: '24 Months' },
    { value: '23', label: '23 Months' },
    { value: '24', label: '24 Months' },
  ];
  getparamlist: any = [];
  saveparamlist: any = [];
  organization: any = [];
  // public effectiveafter;
  hour: any;
  noOfTimesPermissionAllowed: any;
  isValidTime: boolean = false;
  timepicker;
  meridian = true;
  spinners = false;

  constructor(private encryptdecryptlocalstorage: EncryptDecryptLocalStorageService, private genericservice: Genericservice) {
    // this.timepicker = { hour: 1, minute: 30};
  }

  ngOnInit() {
    this.validateForm();
    this.getleaveparam();
    this.subscribe();
    this.getUserById();
  }
  getUserById() {
    let reqPayload = {
      "gemsUserMasterId": this.encryptdecryptlocalstorage.getLocalStorage('userId')
    }
    return this.genericservice.post(UrlConstant.getEmployeeApi, reqPayload, false).subscribe(response => {
      this.organization = response.data.gemsOrganisation;
    })
  }

  onhrSelectionChange(entry): void {
    this.hour = entry;
    this.validateTime();
  }

  onSelectionChange(val): void {
    this.noOfTimesPermissionAllowed = val;

  }

  subscribe() {
    this.leavedetailsForm.valueChanges.subscribe(value => {

    })
  }


  nonworking = false;
  holiday = false;
  workingdays(event) {
    this.nonworking = !this.nonworking
  }

  // changemonth(event) {

  //   this.effectiveafter=event;

  // }
  validateForm() {
    this.leavedetailsForm = new FormGroup({
      gemsMaxPerHour: new FormControl(),
      noOfTimesPermMonth: new FormControl(),
      timepicker: new FormControl(),
      // maxPermDayHr: new FormControl(),
      // maxPermDayMin: new FormControl(),
      isNonWorkingDay: new FormControl(),
      isHoliday: new FormControl(),
      // effectiveAfter:new FormControl(),
      noOfSickLeave:new FormControl(),
      noOfCasualLeave:new FormControl(),
      noOfEarnedLeave:new FormControl()
    });
  }
  getleaveparam() {
    const payloadObj = {
      gemsOrganisation: { gemsOrgId: this.encryptdecryptlocalstorage.getLocalStorage('organizationId') }
    };
    return this.genericservice.post(this.getleaveparamapiUrl, payloadObj, false).subscribe(response => {
      this.getparamlist = response.data;
      // this.organization = this.getparamlist.gemsOrganisation;
      let maxPermDayHr;
      let maxPermDayMin;
      if (this.getparamlist.maxPermDay) {
        maxPermDayHr = this.getparamlist.maxPermDay.toString().split(".")[0];
        maxPermDayHr = maxPermDayHr.length > 1 ? maxPermDayHr : '0' + maxPermDayHr;
        maxPermDayMin = this.getparamlist.maxPermDay.toString().split(".")[1];
        maxPermDayMin = maxPermDayMin.length > 1 ? maxPermDayMin : maxPermDayMin + '0';
      }
      this.timepicker = { hour: parseInt(maxPermDayHr), minute: parseInt(maxPermDayMin) };

      this.leavedetailsForm.patchValue({
        gemsMaxPerHour:this.getparamlist.gemsMaxPerHour,
        noOfTimesPermMonth:this.getparamlist.noOfTimesPermMonth,
        isNonWorkingDay:this.getparamlist.isNonWorkingDay,
        isHoliday:this.getparamlist.isHoliday,
        noOfCasualLeave:this.getparamlist.noOfCasualLeave,
        noOfSickLeave:this.getparamlist.noOfSickLeave,
        noOfEarnedLeave:this.getparamlist.noOfEarnedLeave,

      })

    });
  }
  onKeyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  validateTime() {
    if (this.leavedetailsForm.value.timepicker) {
      this.leavedetailsForm.value.timepicker.hour = this.leavedetailsForm.value.timepicker.hour > 12 ?
        (this.leavedetailsForm.value.timepicker.hour) - 12 : this.leavedetailsForm.value.timepicker.hour;
      this.isValidTime = false;
      if (this.leavedetailsForm.value.timepicker) {
        if (this.leavedetailsForm.value.gemsMaxPerHour == 0) {
          this.isValidTime = false;
        } else {
          let time;
          time = parseInt(this.leavedetailsForm.value.timepicker.hour) * 60;
          time = time + parseInt(this.leavedetailsForm.value.timepicker.minute);
          this.isValidTime = (time > this.leavedetailsForm.value.gemsMaxPerHour * 60) ? true : false;
        }
      }
    }
  }
  saveparam() {
    this.timepicker.hour = this.timepicker.hour > 12 ? this.timepicker.hour - 12 : this.timepicker.hour;

    const payloadObj = {
      "createdBy": 1,
      "createdOn": new Date(),
      //"effectiveAfter":this.effectiveafter,
      "gemsMaxPerHour": this.leavedetailsForm.value.gemsMaxPerHour,
      "maxPermDay": this.timepicker.hour + '.' + this.timepicker.minute,
      "noOfTimesPermMonth": this.leavedetailsForm.value.noOfTimesPermMonth,
      "gemsOrganisation": this.organization,
      "noOfSickLeave":this.leavedetailsForm.value.noOfSickLeave,
      "noOfCasualLeave":this.leavedetailsForm.value.noOfCasualLeave,
      "noOfEarnedLeave":this.leavedetailsForm.value.noOfEarnedLeave,
      "isActive": true,
      "isHoliday": this.leavedetailsForm.controls.isHoliday.value,
      "isNonWorkingDay": this.leavedetailsForm.controls.isNonWorkingDay.value,
      "updatedBy": 1,
      "updatedOn": new Date(),
    };
    console.log(this.timepicker.hour);
    console.log(this.timepicker.minute);

    
    this.genericservice.post(this.saveparamUrl, payloadObj, true).subscribe(response => {
      this.saveparamlist = response.data;
      this.getleaveparam();
      // this.saveLeaveAccural();
    });
  }

  


}
