import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserHomeComponent } from './user-home.component';
import { UserHomeRouting } from './user-home.routing';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [UserHomeComponent],
  imports: [
    CommonModule,
    UserHomeRouting,
    NgbModule,
  
  ],
  
})
export class UserHomeModule { }
