import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EncryptDecryptLocalStorageService } from './encrypt-decrypt-localstorage.service';

@Injectable({ providedIn: 'root' })
export class AdminAuthGuard implements CanActivate {
    constructor(public router: Router, private encryptdecryptlocalstorage: EncryptDecryptLocalStorageService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let isAdmin = this.encryptdecryptlocalstorage.getLocalStorage('isAdmin') === "true" ? true : false;
        if (isAdmin) {
         //   this.router.navigate(['/user/dashboard']);
            return true;
        }

        this.router.navigate(['/notAuthorized']);
        return false;
    }
}