import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { EncryptDecryptLocalStorageService } from 'src/app/services/encrypt-decrypt-localstorage.service';

@Pipe({
  name: 'paginate'
})
export class PaginatePipe implements PipeTransform {
  // private encryptdecryptlocalstorage: EncryptDecryptLocalStorageService;
  constructor(private cdRef: ChangeDetectorRef, 
    private encryptdecryptlocalstorage: EncryptDecryptLocalStorageService) { }
  transform(value: any, ...args: any): any {
   

    if (!value) return null;
    if (!args) return value;
   
    value.length == 0 ? this.encryptdecryptlocalstorage.setToLocalStorage('noRecordFound', 'true') 
    : this.encryptdecryptlocalstorage.setToLocalStorage('noRecordFound', 'false');
  if(args[0].comptname=='departments'){
    value.length == 0 ? this.encryptdecryptlocalstorage.setToLocalStorage('noRecordFoundDep', 'true') 
    : this.encryptdecryptlocalstorage.setToLocalStorage('noRecordFoundDep', 'false');
  }else if(args[0].comptname=='designations'){
    value.length == 0 ? this.encryptdecryptlocalstorage.setToLocalStorage('noRecordFoundDes', 'true') 
    : this.encryptdecryptlocalstorage.setToLocalStorage('noRecordFoundDes', 'false');
  }
  else if(args[0].comptname=='roles'){
    value.length == 0 ? this.encryptdecryptlocalstorage.setToLocalStorage('noRecordFoundRole', 'true') 
    : this.encryptdecryptlocalstorage.setToLocalStorage('noRecordFoundRole', 'false');
  } 
  else if(args[0].comptname=='manageLeaves'){
    value.length == 0 ? this.encryptdecryptlocalstorage.setToLocalStorage('noRecordFoundMl', 'true') 
    : this.encryptdecryptlocalstorage.setToLocalStorage('noRecordFoundMl', 'false');
  }
  else if(args[0].comptname=='leavePolicy'){
    value.length == 0 ? this.encryptdecryptlocalstorage.setToLocalStorage('noRecordFoundLP', 'true') 
    : this.encryptdecryptlocalstorage.setToLocalStorage('noRecordFoundLP', 'false');
  }
    this.cdRef.detectChanges();
    return value.slice(args[0].start, args[0].limit);
  }

  
}