import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Genericservice } from 'src/app/services/genericservices';
import { UrlConstant } from 'src/app/services/urlConstant';
import { EncryptDecryptLocalStorageService } from 'src/app/services/encrypt-decrypt-localstorage.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { DataSharingService } from 'src/app/services/dataSharing.service';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/sortable.directive';
@Component({
  selector: 'app-leavebalance',
  templateUrl: './leavebalance.component.html',
  styleUrls: ['./leavebalance.component.scss']
})
export class LeavebalanceComponent implements OnInit {
  leavebalancelist=[]
  organisationId: any;
  public leavebalanceListUrl = UrlConstant.getleavebalanceApiWithPagination;
  public editleavebalanceUrl = UrlConstant.editleavebalanceApi;
  public updateleavesummaryUrl = UrlConstant.updateemployeeleavesummaryApi;
  leavebalanceForm: FormGroup;
  leavebalanceObj: any;
  employeeleaveobj: any;
  leavebalance: any;
  upateleavebalancelist: any;
  employeeleaveobjEdit: any;
  public perpageOption = [

    { value: '10', label: '10' },
    { value: '20', label: '20' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '500', label: '500' },
  ];
  selectedItemsPerPage: number = 10;
  totalCount: any;
  start: any;
  limit: any;
  totalPages: number;
  currentPage = 1;
  public filterkey: string;
  isPreloader:boolean=false;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(private genericservice: Genericservice,
    private encryptdecryptlocalstorage: EncryptDecryptLocalStorageService,
    private dataSharingService: DataSharingService,
  ) {

  }
  ngOnInit() {
    this.start = 0;
    this.limit = this.selectedItemsPerPage;
    this.getleavebalance();
    this.leavebalanceForm = new FormGroup({
      leaveTypeDescription: new FormControl('', [Validators.required]),
      leaveEntitled: new FormControl('', [Validators.required])
    })

  }

  //  GETTING LEAVEBALANCE LIST

  getleavebalance() {

    let payload = {
      "gemsOrganisation": {
        "gemsOrgId": this.encryptdecryptlocalstorage.getLocalStorage('organizationId'),
      },

    }
    this.isPreloader=true;
    this.totalCount =0;
    var req = `?start=${this.start}&limit=${this.limit}`;
    this.genericservice.post(this.leavebalanceListUrl+req, payload, false).subscribe(response => {
      this.isPreloader=false;
      if(response.data){
        this.leavebalancelist = response.data;
         this.totalCount = response.totalResult;
         this.findItemsPerPage();
      }
    },(error)=>{
      this.isPreloader=false;
    });
  }

  
  goToNext() {
    this.currentPage++;
    this.start++;
    this.limit = this.selectedItemsPerPage;
    this.onChangePagination(this.filterkey);
    var obj:any={column:'', direction:''}
    this.onSort(obj);
  }
  goToPrev() {
    this.currentPage--;
    this.start--;
    this.limit = this.selectedItemsPerPage;
    this.onChangePagination(this.filterkey);
    var obj:any={column:'', direction:''}
    this.onSort(obj);
  }
  findItemsPerPage() {
    this.totalPages = Math.ceil(this.totalCount / this.selectedItemsPerPage);
  }
  changeItemPerPage(event) {
    this.currentPage = 1;
    this.selectedItemsPerPage = parseInt(event);
    this.start = 0;
    this.limit = this.selectedItemsPerPage;
    this.onChangePagination(this.filterkey);
    var obj:any={column:'', direction:''}
    this.onSort(obj);
  }
  goToPageNo(event) {
  }


  // EDITLEAVEBALANCELIST
  editleavebalance(leavebalance) {

  
    // this.leavebalanceForm.reset();
    this.isPreloader=true;

    let payload = {
      "gemsEmployeeMasterId": leavebalance.gemsEmployeeMasterId
    }
    this.genericservice.post(this.editleavebalanceUrl, payload, false).subscribe(response => {
      this.employeeleaveobj = response.data;
      this.employeeleaveobjEdit = response.data;
      this.isPreloader=false;


    })
  }

resetform(){
  this.leavebalanceForm.reset();
}

  // UPDATING LEAVEBALANCE
  updateleavebalance(employeeleaveobj) {
    this.genericservice.post(this.updateleavesummaryUrl, employeeleaveobj, false).subscribe(response => {
      if (response.status.success == 'success' || response.status.success == 'Success') {

        this.dataSharingService.successMessage(response.status.message);
        
      }
      else if (response.status.success == 'Fail' || response.status.success == 'fail') {
        this.dataSharingService.failureMessage(response.status.message);
      }
      this.ngOnInit();

    })
  }

  leaveBalanceOnChange(event: any) {
    this.employeeleaveobjEdit[+event.target.id].leaveEntitled = +event.target.value;
    
     
     
  }
  public getFromLocalStorage(){
    let isDataFound = this.encryptdecryptlocalstorage.getLocalStorage('noRecordFoundMl');
    return isDataFound;
    }

    onChangePagination(search?: string) { 
      let payload = {
        "gemsOrganisation": {
          "gemsOrgId": this.encryptdecryptlocalstorage.getLocalStorage('organizationId'),
        },
      }
      this.isPreloader=true;
      var req =search?
      `?start=${0}&limit=${this.limit}&search=${search}`:
      `?start=${this.start}&limit=${this.limit}`;
      this.genericservice.post(this.leavebalanceListUrl+req, payload, false).subscribe(response => {
        this.isPreloader=false;
        if(response.data){
          this.leavebalancelist = response.data;
          this.totalCount = response.totalResult;
          this.findItemsPerPage();
        }
        else{
         this.leavebalancelist=[];
         this.totalCount = 0;
        }
      },error=>{
        this.isPreloader=false;
      })
    }

    onSort({column, direction}: SortEvent) {

      // resetting other headers
      this.headers.forEach(header => {
        if (header.sortable !== column) {
          header.direction = '';
        }
      });
  
      // sorting countries
      if (direction === '') {
        this.leavebalancelist = this.leavebalancelist;
      } else {
        this.leavebalancelist = this.leavebalancelist.sort((a, b) => {
          const res = UrlConstant.compare(a[column].toString().toLowerCase(), b[column].toString().toLowerCase());
          return direction === 'asc' ? res : -res;
        });
      }
    }




    onSortNumber({column, direction}: SortEvent) {

      // resetting other headers
      this.headers.forEach(header => {
        if (header.sortable !== column) {
          header.direction = '';
        }
      });
  
      // sorting countries
      if (direction === '') {
        this.leavebalancelist = this.leavebalancelist;
      } else {
        this.leavebalancelist = this.leavebalancelist.sort((a, b) => {
         
  
          const res = UrlConstant.compare(a[column]?a[column]:null, b[column]?b[column]:null);
          return direction === 'asc' ? res : -res;
        });
      }
    }

}
