import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { EncryptDecryptLocalStorageService } from '../../../services/encrypt-decrypt-localstorage.service';
import { EncryptDecryptService } from '../../../services/encrypt-decrypt.service';
import { Router } from '@angular/router';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';

import { Genericservice } from '../../../services/genericservices';
import { UrlConstant } from '../../../services/urlConstant';
import { DataSharingService } from 'src/app/services/dataSharing.service';
import * as moment from 'moment';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/sortable.directive';
@Component({
  selector: 'app-shift',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.scss']
})
export class ShiftComponent implements OnInit {
  shiftList: any = [];
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(private router: Router, private urlconstant: UrlConstant, private genericservice: Genericservice,
    private encryptdecryptservice: EncryptDecryptService,
    private encryptdecryptlocalstorage: EncryptDecryptLocalStorageService,
    private dataSharingService: DataSharingService, ) { }
  deleteSelectedObj: any
  permissionDate = 'DATE';
  startTimepickerAM = true;
  startTimepickerPM = false;
  endTimepickerAM = false;
  endTimepickerPM = true;
  shiftForm: FormGroup;
  editShiftForm: FormGroup;
  inTime;
  outTime;
  editSelcetdObj;
  meridian = true;
  spinners = false;
  checkTimeEqualFlag: any
  ngOnInit() {
    this.getShiftList()
    this.shiftForm = new FormGroup({
      shiftName: new FormControl('', [Validators.required]),
      shiftInTime: new FormControl(''),
      shiftOutTime: new FormControl(''),
      inTimeControl: new FormControl(''),
      outTimeControl: new FormControl('')
    });
    this.editShiftForm = new FormGroup({
      shiftName: new FormControl('', [Validators.required]),
      shiftInTime: new FormControl(''),
      shiftOutTime: new FormControl(''),
      inTimeControl: new FormControl(''),
      outTimeControl: new FormControl('')
    });

  }
  getShiftList() {

    let payload = {
      "gemsOrganisation": {
        "gemsOrgId": parseInt(this.encryptdecryptlocalstorage.getLocalStorage('organizationId'))
      }

    }
    this.genericservice.post(UrlConstant.getShift, payload, false).subscribe((response) => {

      this.shiftList = response.data

    })



  }

  deleteShift(deleteObj) {
    this.deleteSelectedObj = deleteObj

  }
  editShift(editObj) {



    this.editSelcetdObj = editObj
    var intime = new Date(editObj.shiftInTime)
    var outtime = new Date(editObj.shiftOutTime)

    var inTimeControl = {
      "hour": intime.getUTCHours(),
      "minute": intime.getUTCMinutes(),
      "second": intime.getUTCSeconds()
    }
    var outTimeControl = {
      "hour": outtime.getUTCHours(),
      "minute": outtime.getUTCMinutes(),
      "second": outtime.getUTCSeconds()
    }

    this.editShiftForm.controls.shiftName.patchValue(editObj.shiftName);
    this.editShiftForm.controls.inTimeControl.patchValue(inTimeControl)
    this.editShiftForm.controls.outTimeControl.patchValue(outTimeControl)



  }
  confirmDelete() {

    this.deleteSelectedObj.isActive = false



    this.genericservice.post(UrlConstant.deleteShift, this.deleteSelectedObj, false).subscribe((response) => {




      if (response.status.success == 'success' || response.status.success == 'Success') {

        this.dataSharingService.successMessage('Deleted successfully');
        this.getShiftList()
      }
      else if (response.status.success == 'Fail' || response.status.success == 'fail') {
        this.dataSharingService.failureMessage(response.status.message);
      }


    })



  }

  Unix_timestamp(t) {

    var date = new Date(t).getUTCHours();
    var hr = new Date(t).getUTCHours()
    var m = new Date(t).getUTCMinutes() < 10 ? '0' + new Date(t).getUTCMinutes() : new Date(t).getUTCMinutes()
    var time = hr + ':' + m
    if (hr < 12) {
      time = hr + ':' + m + 'AM'
    } else if (hr == 12) {
      time = hr + ':' + m + 'PM'
    }
    else {
      time = hr - 12 + ':' + m + 'PM'
    }


    return time



  }


  reset() {
    this.shiftForm.reset();
    this.editShiftForm.reset()
  }

  setShiftTimeValues() {

    if (this.shiftForm.value.inTimeControl.hour != this.shiftForm.value.outTimeControl.hour 
     ) {
      this.checkTimeEqualFlag = ''
      let newdate = new Date()
      this.inTime = this.shiftForm.value.inTimeControl
      this.outTime = this.shiftForm.value.outTimeControl

      this.shiftForm.value.isActive = true;
      this.shiftForm.value.createdBy = parseInt(this.encryptdecryptlocalstorage.getLocalStorage('employeeId'))
      this.shiftForm.value.updatedBy = 1;
      this.shiftForm.value.shiftName = this.shiftForm.value.shiftName.trim();
      let today = new Date();
      today.setHours(this.inTime.hour);
      today.setMinutes(this.inTime.minute);
      today.setSeconds(0);
      let today1 = new Date();
      today1.setHours(this.outTime.hour);
      today1.setMinutes(this.outTime.minute);
      today1.setSeconds(0);
      this.shiftForm.value.shiftInTime = today;
      this.shiftForm.value.shiftOutTime = today1;
      this.shiftForm.value.createdOn = new Date();
      this.shiftForm.value.updatedOn = new Date();
      let organisation = { "gemsOrgId": parseInt(this.encryptdecryptlocalstorage.getLocalStorage('organizationId')) }
      this.shiftForm.value.gemsOrganisation = organisation;
      let DateInString = newdate.getFullYear() + '-' + newdate.getMonth() + '-' + newdate.getDate();
      let shiftIntime = DateInString + ' ' + this.inTime.hour + ':' + this.inTime.minute + ':' + '00';
      let shiftOuttime = DateInString + ' ' + this.outTime.hour + ':' + this.outTime.minute + ':' + '00';
      let saveShiftUrl = UrlConstant.saveShift + "shiftInTime=" + shiftIntime + '&shiftOutTime=' + shiftOuttime
      this.genericservice.post(saveShiftUrl, this.shiftForm.value, true).subscribe((response) => {
        if (response.status.success == "Success" || response.status.success == "success") {
          this.getShiftList();
          document.getElementById('dismiss_modal_add').click();
          this.reset();
        }
      })
    } else {
      this.checkTimeEqualFlag = "Intime Outtime should not same"
    }

  }

  editShiftTimeValues() {
    if (this.editShiftForm.value.inTimeControl.hour != this.editShiftForm.value.outTimeControl.hour 
    ) {
      this.checkTimeEqualFlag = ''
    this.inTime = this.editShiftForm.value.inTimeControl
    this.outTime = this.editShiftForm.value.outTimeControl
    this.editShiftForm.value.isActive = true;
    this.editShiftForm.value.createdBy = parseInt(this.encryptdecryptlocalstorage.getLocalStorage('employeeId'))
    this.editShiftForm.value.updatedBy = 1;
    this.editShiftForm.value.shiftName = this.editShiftForm.value.shiftName.trim();
    this.editShiftForm.value.shiftDetailId = this.editSelcetdObj.shiftDetailId
    let today = new Date();
    today.setHours(this.inTime.hour);
    today.setMinutes(this.inTime.minute);
    today.setSeconds(0);
    let today1 = new Date();
    today1.setHours(this.outTime.hour);
    today1.setMinutes(this.outTime.minute);
    today1.setSeconds(0);
    this.editShiftForm.value.shiftInTime = today;
    this.editShiftForm.value.shiftOutTime = today1;
    this.editShiftForm.value.createdOn = new Date();
    this.editShiftForm.value.updatedOn = new Date();
    let organisation = {
      "gemsOrgId": parseInt(this.encryptdecryptlocalstorage.getLocalStorage('organizationId'))
    }
    this.editShiftForm.value.gemsOrganisation = organisation;
    let DateInString = today.getFullYear() + '-' + today.getMonth() + 1 + '-' + today.getDate();
    let shiftIntime = DateInString + ' ' + this.inTime.hour + ':' + this.inTime.minute + ':' + '00';
    let shiftOuttime = DateInString + ' ' + this.outTime.hour + ':' + this.outTime.minute + ':' + '00';
    let saveShiftUrl = UrlConstant.saveShift + "shiftInTime=" + shiftIntime + '&shiftOutTime=' + shiftOuttime
    this.genericservice.post(saveShiftUrl, this.editShiftForm.value, true).subscribe((response) => {
      if (response.status.success == "Success" || response.status.success == "success") {
        this.getShiftList();
        document.getElementById('dismiss_modal_edit').click();
        this.reset();
      }
    })
  }
  else {
    this.checkTimeEqualFlag = "Intime Outtime should not same"
  }
}

onSort({column, direction}: SortEvent) {
 
  // resetting other headers
  this.headers.forEach(header => {
    if (header.sortable !== column) {
      header.direction = '';
    }
  });

  // sorting countries
  if (direction === '') {
    this.shiftList = this.shiftList;
  } else {
    this.shiftList = this.shiftList.sort((a, b) => {
      const res = UrlConstant.compare(a[column].toString().toLowerCase(), b[column].toString().toLowerCase());
      return direction === 'asc' ? res : -res;
    });
  }
}



onSortNumber({column, direction}: SortEvent) {

  // resetting other headers
  this.headers.forEach(header => {
    if (header.sortable !== column) {
      header.direction = '';
    }
  });

  // sorting countries
  if (direction === '') {
    this.shiftList = this.shiftList;
  } else {
    this.shiftList = this.shiftList.sort((a, b) => {
     

      const res = UrlConstant.compare(a[column]?a[column]:null, b[column]?b[column]:null);
      return direction === 'asc' ? res : -res;
    });
  }
}




onSortDate({ column, direction }: SortEvent) {

  // resetting other headers
  this.headers.forEach(header => {
    if (header.sortable !== column) {
      header.direction = '';
    }
  });

  // sorting countries
  if (direction === '') {
    this.shiftList == 0;
    this.shiftList.data = this.shiftList.data;
  } else {
    this.shiftList.data = this.shiftList.data.sort((a, b) => {
      const res = UrlConstant.compareDate(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}




}

