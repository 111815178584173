import { Component, OnInit, Input, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { UrlConstant } from 'src/app/services/urlConstant';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { EncryptDecryptLocalStorageService } from 'src/app/services/encrypt-decrypt-localstorage.service';
import { Genericservice } from 'src/app/services/genericservices';
import { DataSharingService } from 'src/app/services/dataSharing.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/sortable.directive';

@Component({
  selector: 'app-leavepolicy',
  templateUrl: './leavepolicy.component.html',
  styleUrls: ['./leavepolicy.component.scss'],
})
export class LeavepolicyComponent implements OnInit {

  leaveTypesURL = UrlConstant.getLeaveTypes;
  EmploymentstatusListURL = UrlConstant.newApiEmploymentStatusList;
  leaveSummariesURL = UrlConstant.getLeaveSummariesWithPagination;
  saveOrgLeaveSummariesURL = UrlConstant.saveOrgLeaveSummaries;
  getLeaveSummeryByIdURL = UrlConstant.getLeaveSummerybyId;
  deleteLeaveSummeryURL = UrlConstant.deleteLeaveSummay;
  downloadSampleExcelFileURL = UrlConstant.downloadExcelFile;
  uploadLeavePolicyExcelFileURL = UrlConstant.uploadLeavePolicyExcelFile;


  public leavePolicyForm: FormGroup;
  public leaveTypeList = [];
  public leaveSummariesList=[];
  public employementsStatusList = [];
  public editLeavePolicyObject: any;
  public selectedSourecFile: File;
  public manipulation = 'ADD';
  public filterkey: string;
  public errorMessage = '';
  isShow = false;
  totalCount: any;
  start = 0;
  limit = 10;
  totalPages: number;
  currentPage = 1;
  selectedItemsPerPage = 10;
  paginateSummaryList: any;
  public pageOptions = [

    { value: '10', label: '10' },
    { value: '20', label: '20' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '500', label: '500' },
  ];
  selectExcelFileName: any = '';
  order: any;
  orderBy: any;
  public orderBoolean = false;
  orgId: any;
  public leavePolicyName: any;
  public deletePayload: any;
  public modalRef: NgbModalRef;
  preloader:boolean=false;
  @ViewChild('myInput', { static: true })
  myInputVariable: ElementRef;
  public showNumberOfLeaveCount: boolean = true;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  leaveTypeCode: any;
  constructor(private formbuilder: FormBuilder,
    private genericService: Genericservice,
    private encryptdecryptlocalstorage: EncryptDecryptLocalStorageService,
    private dataSharingService: DataSharingService,
    private modalService: NgbModal) {
    if (this.encryptdecryptlocalstorage.getLocalStorage('organizationId') == "" || this.encryptdecryptlocalstorage.getLocalStorage('organizationId') == undefined
      || this.encryptdecryptlocalstorage.getLocalStorage('organizationId') == null) {
      this.orgId = parseInt(this.encryptdecryptlocalstorage.getLocalStorage('organisationId'));
    } else {
      this.orgId = parseInt(this.encryptdecryptlocalstorage.getLocalStorage('organizationId'));
    }
    this.createLeavePolicyForm();
    this.subscribeLeavePolicyForm();
  }

  ngOnInit() {
    this.getLeaveSummariesList();
    this.getLeaveTypes();
    this.getEmployementsStatusList();

    this.dataSharingService.leaveTypeListObs.subscribe((data) => {
      if (data == 'true') {

        this.getLeaveTypes();


      }
    })

  }
  getLeaveTypes() {
    var payload = {
      "gemsOrganisation":
        { "gemsOrgId": this.orgId },
      "isActive": true
    };
    var url=this.leaveTypesURL+"?start=0&limit=100"
    this.genericService.postWithOutHeaders(url, payload, false).subscribe(response => {
      this.leaveTypeList = response.data;
    });
  }
 

  public resetForm() {
    this.errorMessage = '';
    this.selectExcelFileName = '';
    this.leavePolicyForm.reset();
    this.manipulation = 'ADD';
    this.myInputVariable.nativeElement.value = "";
    document.getElementById('dismiss_modal').click();
  }

  setOrder(value: string, orderBy) {
    if (this.order === value) {
      // this.reverse = !this.reverse;
      this.orderBoolean = !this.orderBoolean;
      this.orderBy = orderBy;
    }
    this.order = value;
  }

  public getLeaveSummariesList() {
    var payload = { "gemsOrganisationId": this.orgId };
    var req =`?start=${this.start}&limit=${this.limit}`;
    this.preloader=true;
    this.genericService.postWithOutHeaders(this.leaveSummariesURL+req, payload, false).subscribe(response => {
      this.preloader=false;
    if(response.data!=null){
      this.leaveSummariesList = response.data;
      for(let leaveSummary of this.leaveSummariesList){
        if(leaveSummary.gemsEmployeeAccruvalType=='M'){
          leaveSummary.gemsEmployeeAccruvalType1='Monthly';
        }
        else{
          leaveSummary.gemsEmployeeAccruvalType1='Yearly';
        }
      }
      this.totalCount = response.totalResult;
      this.findItemsPerPage();
    }
    });
  }

  // method to get Employements StatusList 

  public getEmployementsStatusList() {
    var payload = { "gemsOrganisation": { "gemsOrgId": this.orgId } };
    this.genericService.postWithOutHeaders(this.EmploymentstatusListURL, payload, false).subscribe(response => {
      this.employementsStatusList = response.data;
    });
  }


  goToNext() {
    this.currentPage++;
    this.start++;
    this.limit = this.selectedItemsPerPage;
    this.onChangePagination();
    var obj:any={column:'', direction:''}
    this.onSort(obj);

  }
  goToPrev() {
    this.currentPage--;
    this.start--;
    this.limit = this.selectedItemsPerPage;
    this.onChangePagination();
    var obj:any={column:'', direction:''}
    this.onSort(obj);

  }
  findItemsPerPage() {
    this.totalPages = Math.ceil(this.totalCount / this.selectedItemsPerPage);
  }
  changeItemPerPage(event) {
    this.currentPage = 1;
    this.selectedItemsPerPage = parseInt(event);
    this.start = 0;
    this.limit = this.selectedItemsPerPage;
    this.onChangePagination();
    var obj:any={column:'', direction:''}
    this.onSort(obj);

  }
  goToPageNo(event) {
  }
 

  // method To subscribe the levepolicy form

  public subscribeLeavePolicyForm() {
    this.leavePolicyForm.valueChanges.subscribe(value => {
    })
  }

  // method To get leave summary object 

  public getLeaveSummary(leaveSummayMasterId) {
    this.errorMessage = '';
    this.manipulation = 'UPDATE';
    var payload = { "leaveSummayMasterId": leaveSummayMasterId };

    this.genericService.postWithOutHeaders(this.getLeaveSummeryByIdURL, payload, false).subscribe(response => {
      var data = response.data;
      this.leavePolicyForm.patchValue({
        leaveSummayMasterId: data.leaveSummayMasterId,
        totalDays: data.totalDays,
        isActive: data.isActive,
        gemsEmployeeAccruvalType: data.gemsEmployeeAccruvalType,
        gemsEmploymentStatus: { gemsEmploymentStatusId: data.gemsEmploymentStatus.gemsEmploymentStatusId },
        gemsLeaveTypeMaster: { gemsLeaveTypeMasterId: data.gemsLeaveTypeMaster.gemsLeaveTypeMasterId },
        gemsOrganisation: { gemsOrgId: data.gemsOrganisation.gemsOrgId }
      })
    });
  }

  // create leave policy form structure
  public createLeavePolicyForm() {
    this.leavePolicyForm = this.formbuilder.group({
      leaveSummayMasterId: [''],
      totalDays: ['', [Validators.required, Validators.min(0), Validators.max(364)]],
      isActive: [true],
      gemsEmployeeAccruvalType: ['', Validators.required],
      gemsEmployeeIsAccruval: [true],
      gemsEmploymentStatus: this.formbuilder.group({
        gemsEmploymentStatusId: ['', Validators.required]
      }),
      gemsLeaveTypeMaster: this.formbuilder.group({
        gemsLeaveTypeMasterId: ['', Validators.required],
      }),
      gemsOrganisation: this.formbuilder.group({
        gemsOrgId: [this.orgId]
      }),
    })
  }

  // method to save the leave policy

  public submitPolicyForm() {
   
    this.preloader=true;
    this.errorMessage = '';
    if (this.manipulation == "ADD") {
      delete this.leavePolicyForm.value.leaveSummayMasterId;
      this.leavePolicyForm.patchValue({
        isActive: true,
        gemsEmployeeIsAccruval: true,
        gemsOrganisation: { gemsOrgId: this.orgId },

      })
      this.leavePolicyForm.value.createdOn = new Date();
      this.leavePolicyForm.value.createdBy = this.encryptdecryptlocalstorage.getLocalStorage('employeeId');
    }
    if (this.manipulation == "ADD") {
      this.leavePolicyForm.value.updatedOn = new Date();
      this.leavePolicyForm.value.updatedBy = this.encryptdecryptlocalstorage.getLocalStorage('employeeId');
    }
   


      this.genericService.postWithOutHeaders(this.saveOrgLeaveSummariesURL, this.leavePolicyForm.value, true).subscribe(response => {
        if (response.status.success == "Success") {

          document.getElementById('dismiss_modal').click();
          this.getLeaveSummariesList();
          this.errorMessage = '';
        } else if (response.status.success == 'Fail') {
          document.getElementById('dismiss_modal').click();
          this.errorMessage = response.status.message;
        }
      }, (error) => {
        this.errorMessage = error.error.message;
      })
      this.preloader=false;

  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // method To open deleteModal
  openDeleteModal(content, leavepolicy) {
    const modalConfig: any = {
      backdrop: 'static',
      size: 'md'
    };
    this.modalRef = this.modalService.open(content, modalConfig);
    this.modalRef.result
      .then((result) => {
        this.getLeaveSummariesList();
      }, (onDismiss) => {
      });

    this.leavePolicyName = leavepolicy.leaveTypeDescription;
    var payload = { "leaveSummayMasterId": leavepolicy.leaveSummayMasterId };

    this.genericService.postWithOutHeaders(this.getLeaveSummeryByIdURL, payload, false).subscribe(response => {
      this.deletePayload = response.data;
    });

  }

  // method To delete the Leave Summary 

  deleteLeaveSummary() {
    this.deletePayload.isActive = false;
    if (this.deletePayload.isActive == false) {
      this.genericService.postWithOutHeaders(this.deleteLeaveSummeryURL, this.deletePayload, true).subscribe(response => {
        if (response.status.success == 'Success') {
          this.closeModal();
        }
        else{
          this.closeModal();
        }
      },error=>{

      });
    }

  }

  dismissModal() {
    this.modalRef.dismiss();
  }
  closeModal() {
    this.modalRef.close();
  }
  // To Download The sample excel file 

  downloadSampleFile() {
    location.href = this.downloadSampleExcelFileURL;
  }

  selectExcelFile(event) {
    const reader = new FileReader();
    if (event.currentTarget.files[0].name.includes('leavePolicies_sample')) {
    if (event.target.files && event.target.files[0]) {
      this.errorMessage = '';
      if (event.target.files[0].name.split('.')[1] == 'xlsx'
        || event.target.files[0].name.split('.')[1] == 'xls') {
        this.selectedSourecFile = event.target.files[0];
        this.selectExcelFileName = event.target.files[0].name;
        reader.readAsDataURL(event.target.files[0]); // read file as data url
      }
      else {
        this.errorMessage = 'Please upload file having extensions .xls/.xlsx';

      }

    }
  }
  else{
    this.errorMessage = 'Please upload only downloaded sample file';
  }
  }

  uploadFile() {
    if (this.selectedSourecFile && this.errorMessage == '') {
      this.genericService.fileUpload(this.uploadLeavePolicyExcelFileURL, this.selectedSourecFile,
        this.orgId, true)
        .subscribe(response => {
          if (response.type == 4) {
            if (response['body'].status.success == 'Fail') {
              this.errorMessage = response['body'].status.message;
              this.myInputVariable.nativeElement.value = "";
            }
            else if (response['body'].status.success == 'Success') {
              this.selectExcelFileName = '';
              this.myInputVariable.nativeElement.value = "";
              this.errorMessage='';
              this.getLeaveSummariesList();
              document.getElementById('upload_modal').click();
            }
          }
        }, error => {
          this.errorMessage = 'Something went wrong';
        })
    }
  }
  

  public get totalDays(): AbstractControl {
    return this.leavePolicyForm.get('totalDays');
  }
  public get isActive(): AbstractControl {
    return this.leavePolicyForm.get('isActive');
  }
  public get gemsLeaveTypeMasterId(): AbstractControl {
    return this.leavePolicyForm.get('gemsLeaveTypeMaster').get('gemsLeaveTypeMasterId');
  }

  public get gemsEmployeeIsAccruval(): AbstractControl {
    return this.leavePolicyForm.get('gemsEmployeeIsAccruval');
  }
  public get gemsEmploymentStatusId(): AbstractControl {
    return this.leavePolicyForm.get('gemsEmploymentStatus').get('gemsEmploymentStatusId');
  }
  public get gemsEmployeeAccruvalType(): AbstractControl {
    return this.leavePolicyForm.get('gemsEmployeeAccruvalType');
  }

  getLeaveTypeMaster(leave) {
    this.leaveTypeList.forEach(leaveobj =>{
      if(leaveobj.gemsLeaveTypeMasterId === +leave.target.value) {
        if(leaveobj.leaveTypeCode !=="OD" && leaveobj.leaveTypeCode !=="PER") {
          this.showNumberOfLeaveCount = true;
        } else {
          this.showNumberOfLeaveCount = false;
        }
      }
    })
  }

  public getFromLocalStorage(){
    let isDataFound = this.encryptdecryptlocalstorage.getLocalStorage('noRecordFoundLP');
    return isDataFound;
    }

    onChangePagination(search?: string) { 
      const requestPayload = { "gemsOrganisationId": this.orgId };
      
        this.preloader = true;
        this.leaveSummariesList=[];
        var req =search?
        `?start=${0}&limit=${this.limit}&search=${search}`:
        `?start=${this.start}&limit=${this.limit}`;
        this.genericService.post(this.leaveSummariesURL + req, requestPayload, false).subscribe(response => {
          this.preloader = false;
          if (response.data) {
            this.leaveSummariesList = response.data;
            this.totalCount = response.totalResult;
            this.findItemsPerPage();
          }else{
            this.leaveSummariesList = [];
            this.totalCount=0;
          }
         
        }, error => {
          this.preloader = false;
        });
    }
    onSort({column, direction}: SortEvent) {

      // resetting other headers
      this.headers.forEach(header => {
        if (header.sortable !== column) {
          header.direction = '';
        }
      });
  
      // sorting countries
      if (direction === '') {
        this.leaveSummariesList = this.leaveSummariesList;
      } else {
        this.leaveSummariesList = this.leaveSummariesList.sort((a, b) => {
          const res = UrlConstant.compare(a[column].toString().toLowerCase(), b[column].toString().toLowerCase());
          return direction === 'asc' ? res : -res;
        });
      }
    }

}
