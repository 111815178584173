import { Component, OnInit, Input, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { UrlConstant } from 'src/app/services/urlConstant';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { EncryptDecryptLocalStorageService } from 'src/app/services/encrypt-decrypt-localstorage.service';
import { Genericservice } from 'src/app/services/genericservices';
import { DataSharingService } from 'src/app/services/dataSharing.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { NgbdSortableHeader, SortEvent } from 'src/app/directives/sortable.directive';

@Component({
  selector: 'signup-admin-leavepolicy',
  templateUrl: './leavepolicy.component.html',
  styleUrls: ['./leavepolicy.component.scss'],
})
export class LeavepolicyComponent implements OnInit {

  leaveTypesURL = UrlConstant.getLeaveTypes;
  EmploymentstatusListURL = UrlConstant.newApiEmploymentStatusList;
  leaveSummariesURL = UrlConstant.getLeaveSummaries;
  saveOrgLeaveSummariesURL = UrlConstant.saveOrgLeaveSummaries;
  getLeaveSummeryByIdURL = UrlConstant.getLeaveSummerybyId;
  deleteLeaveSummeryURL = UrlConstant.deleteLeaveSummay;
  downloadSampleExcelFileURL = UrlConstant.downloadExcelFile;
  uploadLeavePolicyExcelFileURL = UrlConstant.uploadLeavePolicyExcelFile;


  public leavePolicyForm: FormGroup;
  public leaveTypeList = [];
  public leaveSummariesList: any = [];
  public employementsStatusList = [];
  public editLeavePolicyObject: any;
  public selectedSourecFile: File;
  public manipulation = 'ADD';
  public filterkey: string;
  public errorMessage = '';
  leaveSummaries: any = [];
  public preloader: boolean = false;

  totalCount: any;
  start = 0;
  limit = 10;
  totalPages: number;
  currentPage = 1;
  numArray;
  selectedItemsPerPage = 10;
  paginateSummaryList: any;
  public pageOptions = [

    { value: '10', label: '10' },
    { value: '20', label: '20' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '500', label: '500' },
  ];
  selectExcelFileName: any = '';
  order: any;
  orderBy: any;
  public orderBoolean = false;
  orgId: any;
  public leavePolicyName: any;
  public deletePayload: any;
  public modalRef: NgbModalRef;
  public duplLeaveTypeADdFlag: Boolean
  public duplicateTypeEditFlag: Boolean
  currentId = 0;
  @ViewChild('myInput', { static: true })
  myInputVariable: ElementRef;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  leaveTypeCode: any;
  constructor(private formbuilder: FormBuilder,
    private genericService: Genericservice,
    private encryptdecryptlocalstorage: EncryptDecryptLocalStorageService,
    private dataSharingService: DataSharingService,
    private modalService: NgbModal,
    private router: Router) {
    if (this.encryptdecryptlocalstorage.getLocalStorage('organizationId') == "" || this.encryptdecryptlocalstorage.getLocalStorage('organizationId') == undefined
      || this.encryptdecryptlocalstorage.getLocalStorage('organizationId') == null) {
      this.orgId = parseInt(this.encryptdecryptlocalstorage.getLocalStorage('organisationId'));
    } else {
      this.orgId = parseInt(this.encryptdecryptlocalstorage.getLocalStorage('organizationId'));
    }
    this.duplLeaveTypeADdFlag = false
    this.createLeavePolicyForm();
    this.subscribeLeavePolicyForm();
    this.getEmployementsStatusList();
  }

  ngOnInit() {
    this.inputActive();
    // this.getLeaveSummariesList();
    this.getLeaveTypes();
   

    this.dataSharingService.leaveTypeListObs.subscribe((data) => {
      if (data == 'true') {
        this.getEmployementsStatusList();
        this.getLeaveTypes();


      }
    })

  }
  getLeaveTypes() {
    var payload = {
      "gemsOrganisation":
        { "gemsOrgId": this.orgId },
      "isActive": true
    };

    var url=this.leaveTypesURL+"?start=0&limit=100"
    this.genericService.postWithOutHeaders(url, payload, false).subscribe(response => {
      this.leaveTypeList = response.data;
    });
  }
  inputActive() {
    $(function () {
      const inputValueCheck = $(this).val();
      if (inputValueCheck === '') {
        $('.form-group').addClass('focused');
      }
    });

    $('.form-control').focus(function () {
      $(this)
        .parents('.form-group')
        .addClass('focused');
    });

    $('.form-control').blur(function () {
      const inputValue = $(this).val();
      if (inputValue === '') {
        $(this).removeClass('filled');
        $(this)
          .parents('.form-group')
          .removeClass('focused');
      } else {
        $(this).addClass('filled');
      }
    });
  }

  public resetForm() {
    this.errorMessage = '';
    this.selectExcelFileName = '';
    this.leavePolicyForm.reset();
    this.manipulation = 'ADD';
    this.myInputVariable.nativeElement.value = "";
    document.getElementById('dismiss_modal').click();
    this.inputActive();
  }

  setOrder(value: string, orderBy) {
    if (this.order === value) {
      // this.reverse = !this.reverse;
      this.orderBoolean = !this.orderBoolean;
      this.orderBy = orderBy;
    }
    this.order = value;
  }

  public getLeaveSummariesList() {
    
    var payload = { "gemsOrganisationId": this.orgId };
    this.genericService.postWithOutHeaders(this.leaveSummariesURL, payload, false).subscribe(response => {
  
      this.leaveSummariesList = response.data;
      this.totalCount = response.data.length;
      this.findItemsPerPage();
    });
  }

  // method to get Employements StatusList 

   getEmployementsStatusList() {
    //  
   this.preloader=true;
    var payload = { "gemsOrganisation": { "gemsOrgId": this.orgId } };
    this.genericService.postWithOutHeaders(this.EmploymentstatusListURL,
       payload, false).subscribe(response => {
      this.preloader=false;
      this.employementsStatusList = response.data;
    });
  }


  goToNext() {
    this.currentPage++;
    this.start++;
    this.limit = this.selectedItemsPerPage;
    var obj:any={column:'', direction:''}
    this.onSort(obj);
  }
  goToPrev() {
    this.currentPage--;
    this.start--;
    this.limit = this.selectedItemsPerPage;
    var obj:any={column:'', direction:''}
    this.onSort(obj);
  }
  findItemsPerPage() {
    this.totalPages = Math.ceil(this.totalCount / this.selectedItemsPerPage);
  }
  changeItemPerPage(event) {
    this.currentPage = 1;
    this.selectedItemsPerPage = parseInt(event);
    this.start = 0;
    this.limit = this.selectedItemsPerPage;
    this.findItemsPerPage();
    var obj:any={column:'', direction:''}
    this.onSort(obj);
  }
  goToPageNo(event) {
  }


  // method To subscribe the levepolicy form

  public subscribeLeavePolicyForm() { 
    
    this.leavePolicyForm.valueChanges.subscribe(value => {
    })
  }

  // method To get leave summary object 

  public getLeaveSummary(id) {
    this.currentId = id;
    this.errorMessage = '';
    this.manipulation = 'UPDATE';

    var data: any = this.getSummaryById(id);
    this.leavePolicyForm.patchValue({
      leaveSummayMasterId: data.id,
      gemsEmployeeLeaveCount: data.gemsEmployeeLeaveCount,
      isActive: data.isActive,
      gemsEmployeeAccruvalType: data.gemsEmployeeAccruvalType,
      employeeStatus: { gemsEmploymentStatusId: data.employeeStatus.gemsEmploymentStatusId },
      gemsLeaveTypeMaster: { gemsLeaveTypeMasterId: data.gemsLeaveTypeMaster.gemsLeaveTypeMasterId },
      gemsOrganisation: { gemsOrgId: data.gemsOrganisation.gemsOrgId }
    })

  }


   

  getSummaryById(id) {
    //  
    for (let summary of this.leaveSummariesList) {
      if (summary.id == id) {
        return summary.data;
      }
    }
  }


  // create leave policy form structure
  public createLeavePolicyForm() {
    this.leavePolicyForm = this.formbuilder.group({
      leaveSummayMasterId: [''],
      gemsEmployeeLeaveCount: ['', [Validators.required, Validators.min(0), Validators.max(364)]],
      isActive: [true],
      gemsEmployeeAccruvalType: ['', Validators.required],
      gemsEmployeeIsAccruval: [true],
      employeeStatus: this.formbuilder.group({
        gemsEmploymentStatusId: ['', Validators.required]
      }),
      gemsLeaveTypeMaster: this.formbuilder.group({
        gemsLeaveTypeMasterId: ['', Validators.required],
      }),
      gemsOrganisation: this.formbuilder.group({
        gemsOrgId: [this.orgId]
      }),
    })
  }

  // method to save the leave policy

  public submitPolicyForm() {
   
   
    this.errorMessage = '';
  
    if (this.manipulation == "ADD") {
      
      delete this.leavePolicyForm.value.leaveSummayMasterId;
      this.leavePolicyForm.patchValue({
        isActive: true,
        gemsEmployeeIsAccruval: true,
        gemsOrganisation: { gemsOrgId: this.orgId },

      })
      this.leavePolicyForm.value.createdOn = new Date();
      this.leavePolicyForm.value.createdBy = this.encryptdecryptlocalstorage.getLocalStorage('employeeId');
    }
    if (this.leavePolicyForm.valid) {

      if (this.manipulation == "ADD") {
        
        var id = Math.random();
        
        var obj = {
          "id": id,
          "gemsEmployeeAccruvalType": this.leavePolicyForm.value.gemsEmployeeAccruvalType,
          "gemsOrganisationId": this.leavePolicyForm.value.gemsOrganisation.gemsOrgId,
          "isActive": this.leavePolicyForm.value.isActive,
          "leaveTypeCode": this.getLeaveTypeCode(this.leavePolicyForm.value.gemsLeaveTypeMaster.gemsLeaveTypeMasterId),
          "leaveTypeDescription": this.getLeaveTypeDescription(this.leavePolicyForm.value.gemsLeaveTypeMaster.gemsLeaveTypeMasterId),
          "statusDescription": this.getStatusDescription(this.leavePolicyForm.value.employeeStatus.gemsEmploymentStatusId),
          "gemsEmployeeLeaveCount": this.leavePolicyForm.value.gemsEmployeeLeaveCount,
          "updatedOn": this.leavePolicyForm.value.updatedOn

        }

        this.checkDuplicateRecors(obj)
        if (this.duplLeaveTypeADdFlag == true) {
          this.leaveSummaries.push(obj);
          var completeObj = {
            data: this.leavePolicyForm.value,
            id: id
          }
          this.duplLeaveTypeADdFlag = false
          this.leaveSummariesList.push(completeObj);
          this.dataSharingService.successMessage('Leave policy added succesfully')
        }
      }
      else {
        var leaveSummary = {
          "id": this.currentId,
          "gemsEmployeeAccruvalType": this.leavePolicyForm.value.gemsEmployeeAccruvalType,
          "gemsOrganisationId": this.leavePolicyForm.value.gemsOrganisation.gemsOrgId,
          "isActive": this.leavePolicyForm.value.isActive,
          "leaveTypeCode": this.getLeaveTypeCode(this.leavePolicyForm.value.gemsLeaveTypeMaster.gemsLeaveTypeMasterId),
          "leaveTypeDescription": this.getLeaveTypeDescription(this.leavePolicyForm.value.gemsLeaveTypeMaster.gemsLeaveTypeMasterId),
          "statusDescription": this.getStatusDescription(this.leavePolicyForm.value.employeeStatus.gemsEmploymentStatusId),
          "gemsEmployeeLeaveCount": this.leavePolicyForm.value.gemsEmployeeLeaveCount,
          "updatedOn": this.leavePolicyForm.value.updatedOn

        }

        var completeObj = {
          data: this.leavePolicyForm.value,
          id: this.currentId
        }

        this.checkDuplicateRecorsEdit(leaveSummary)
      
        if (this.duplicateTypeEditFlag == true) {
          
         
          var index = this.leaveSummaries.findIndex(item => item.id == this.currentId);
          
         
          this.leaveSummaries[index] = leaveSummary;

          var index1 = this.leaveSummariesList.findIndex(item => item.id == this.currentId);
          this.leaveSummariesList[index1] = completeObj
          this.duplicateTypeEditFlag = false
          this.dataSharingService.successMessage('Leave policy updated succesfully')
        }

      
      }
      document.getElementById('dismiss_modal').click();
    }
  }
  checkDuplicateRecors(obj) {
     
    if (this.leaveSummaries.length == 0) {
      this.duplLeaveTypeADdFlag = true
    } else {
      for (let exitObj of this.leaveSummaries) {
        if (obj.leaveTypeCode == exitObj.leaveTypeCode && obj.statusDescription == exitObj.statusDescription) {
          this.duplLeaveTypeADdFlag = false
          this.dataSharingService.failureMessage('Duplicate Records Found')
          break
        }
        else {
          this.duplLeaveTypeADdFlag = true
        }
      }
    }

  }
  checkDuplicateRecorsEdit(obj) {
  
    if (this.leaveSummaries.length == 0) {
      this.duplicateTypeEditFlag = true
    } else {
      for (let exitObj of this.leaveSummaries) {
      
        if (obj.id == exitObj.id) {
          this.duplicateTypeEditFlag=true;
          break;
         }
       else if (obj.id != exitObj.id) {

          if (obj.leaveTypeCode == exitObj.leaveTypeCode && obj.statusDescription == exitObj.statusDescription) {
            this.duplicateTypeEditFlag = false
           
            this.dataSharingService.failureMessage('Duplicate Records Found')
            break;
          }
         
        }
       



      }
    }

  }
  getLeaveTypeCode(id) {
    for (let leaveType of this.leaveTypeList) {
      if (leaveType.gemsLeaveTypeMasterId == id) {
        return leaveType.leaveTypeCode
      }
    }

  }
  getLeaveTypeDescription(id) {

    for (let leaveType of this.leaveTypeList) {
      if (leaveType.gemsLeaveTypeMasterId == id) {
        return leaveType.leaveTypeDescription
      }
    }
  }
  getStatusDescription(id) {
    for (let status of this.employementsStatusList) {
      if (status.gemsEmploymentStatusId == id) {
        return status.statusDescription
      }
    }
  }


  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // method To open deleteModal
  openDeleteModal(content, leavepolicy) {
    const modalConfig: any = {
      backdrop: 'static',
      size: 'md'
    };
    this.currentId = leavepolicy.id;
    this.modalRef = this.modalService.open(content, modalConfig);
    this.modalRef.result
      .then((result) => {
        //   this.getLeaveSummariesList();
      }, (onDismiss) => {
      });

    // this.leavePolicyName = leavepolicy.leaveTypeDescription;
    // var payload = { "leaveSummayMasterId": leavepolicy.leaveSummayMasterId };

    // this.genericService.postWithOutHeaders(this.getLeaveSummeryByIdURL, payload, false).subscribe(response => {
    //   this.deletePayload = response.data;
    //  
    // });



  }

  // method To delete the Leave Summary 

  deleteLeaveSummary() {
   
    // this.deletePayload.isActive = false;
    // if (this.deletePayload.isActive == false) {
    //   this.genericService.postWithOutHeaders(this.deleteLeaveSummeryURL, this.deletePayload, true).subscribe(response => {
    //     if (response.status.success == 'Success') {
    //       this.closeModal();
    //     }
    //   });
    // }
     
    
    var index = this.leaveSummaries.findIndex(item => item.id == this.currentId);
    this.leaveSummaries.splice(index, 1);

    var index1 = this.leaveSummariesList.findIndex(item => item.id == this.currentId);
    this.leaveSummariesList.splice(index1, 1)
    this.closeModal();
    this.dataSharingService.successMessage('Leave policy deleted succesfully')
  }

  dismissModal() {
    this.modalRef.dismiss();
    this.inputActive();
  }
  closeModal() {
    this.modalRef.close();
  }
  // To Download The sample excel file 

  downloadSampleFile() {
    location.href = this.downloadSampleExcelFileURL;
  }

  selectExcelFile(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files[0]) {
      this.errorMessage = '';
      if (event.target.files[0].name.split('.')[1] == 'xlsx'
        || event.target.files[0].name.split('.')[1] == 'xls') {
        this.selectedSourecFile = event.target.files[0];
        this.selectExcelFileName = event.target.files[0].name;
        reader.readAsDataURL(event.target.files[0]); // read file as data url
      }
      else {
        this.errorMessage = 'Please upload file having extensions .xls/.xlsx';

      }

    }
  }

  uploadFile() {
    if (this.selectedSourecFile && this.errorMessage == '') {
      this.genericService.fileUpload(this.uploadLeavePolicyExcelFileURL, this.selectedSourecFile,
        this.orgId, true)
        .subscribe(response => {
          if (response.type == 4) {
            if (response['body'].status.success == 'Fail') {
              this.errorMessage = response['body'].status.message;
              this.myInputVariable.nativeElement.value = "";
            }
            else if (response['body'].status.success == 'Success') {
              this.selectExcelFileName = '';
              this.myInputVariable.nativeElement.value = "";
              this.getLeaveSummariesList();
              document.getElementById('upload_modal').click();
            }
          }
        }, error => {
          this.errorMessage = 'Something went wrong';
        })
    }
  }


  public get gemsEmployeeLeaveCount(): AbstractControl {
    return this.leavePolicyForm.get('gemsEmployeeLeaveCount');
  }
  public get isActive(): AbstractControl {
    return this.leavePolicyForm.get('isActive');
  }
  public get gemsLeaveTypeMasterId(): AbstractControl {
    return this.leavePolicyForm.get('gemsLeaveTypeMaster').get('gemsLeaveTypeMasterId');
  }

  public get gemsEmployeeIsAccruval(): AbstractControl {
    return this.leavePolicyForm.get('gemsEmployeeIsAccruval');
  }
  public get gemsEmploymentStatusId(): AbstractControl {
    return this.leavePolicyForm.get('employeeStatus').get('gemsEmploymentStatusId');
  }
  public get gemsEmployeeAccruvalType(): AbstractControl {
    return this.leavePolicyForm.get('gemsEmployeeAccruvalType');
  }
  tabNext() {
    this.preloader = true;
  

  
    var summaries = [];
    for (let summary of this.leaveSummariesList) {
      summaries.push(summary.data);
    }
   
    this.genericService.postwithoutToken(UrlConstant.signupLeavePolicies, summaries, false).subscribe(res => {
     
    
      this.genericService.postwithoutToken(UrlConstant.finishSetUpApi + '?organisationId=' + this.encryptdecryptlocalstorage.getLocalStorage('organisationId'), null, false).subscribe(res => {
        if (res.status.success == 'Success' || res.status.success == 'success') {
          this.preloader = false;
          this.router.navigate(['setupFinish']);
         
        } else {
          this.preloader = false;
          this.dataSharingService.failureMessage(res.status.message);
        }
      },error=>{
        this.preloader = false;
      })
    },error=>{
      this.preloader = false;
    })
  }

  tabPrev() {
    $('#nav-mapping').addClass('active show');
    $('#nav-policy').removeClass('active show');
    $('#nav-mapping-tab').addClass('active show');
    $('#nav-policy-tab').removeClass('active show');
  }

  onSort({column, direction}: SortEvent) {

    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting countries
    if (direction === '') {
      this.leaveTypeCode = this.leaveTypeCode;
    } else {
      this.leaveTypeCode = this.leaveTypeCode.sort((a, b) => {
        const res = UrlConstant.compare(a[column].tostring().toLowerCase(), b[column].tostring().toLowerCase());
        return direction === 'asc' ? res : -res;
      });
    }
  }
  
  onSortNumber({column, direction}: SortEvent) {

    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting countries
    if (direction === '') {
      this.leaveTypeCode = this.leaveTypeCode;
    } else {
      this.leaveTypeCode = this.leaveTypeCode.sort((a, b) => {
       

        const res = UrlConstant.compare(a[column]?a[column]:null, b[column]?b[column]:null);
        return direction === 'asc' ? res : -res;
      });
    }
  }


 
}
